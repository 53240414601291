import { Component, OnInit, Input } from '@angular/core';
import { AjaxService } from 'src/app/services/ajax.service';
import { CommonService } from 'src/app/services/common.service';
import { Router } from '@angular/router';
import { AlertController, ModalController } from '@ionic/angular';
import { serverUrl } from '../../../../environments/environment';
import { UserDetailService } from 'src/app/services/user-detail.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-operatorform',
  templateUrl: './operatorform.component.html',
  styleUrls: ['./operatorform.component.scss'],
})
export class OperatorformComponent implements OnInit {
  @Input() selectedRow;
  @Input() myGrid;
  @Input() value;
  fleetLogin: FormGroup;
  progressText = "Checking inventory";
  formDisplay = false;
  valuePro = "0.25";
  displayBar = false;
  statusBar = false;
  // fleetLogin:any;
  loginStatus;
  data: any;
  editrow: number;
  dataGrid: any = {};
  user: any = {};
  selectedCompany: any;
  fleetManager: any[];
  companyVehicle: FormGroup;
  fleet: any[];
  model: any = [];
  vin: any;
  editVehType = "vehicleType";
  editfleet = this.trans.instant("Country");
  serviceName: any;
  hideSerialNo: boolean = false;
  selectValue = [];


  companyDetail: { branchID: string; companyID: string; userId: string; };
  isDeleteShow: any = false;
  constructor(
    private formBuilder: FormBuilder,
    private modalController: ModalController,
    private userService: UserDetailService,
    private ajaxService: AjaxService,
    private commonService: CommonService,
    private router: Router,
    private alertController: AlertController,
    private trans: TranslateService
  ) { }
  async closeModal() {
    this.modalController.dismiss();
  }
  vehicleTypes = [];
  //  'FORKLIFTS', 'LIGHT TOWERS', 'WELDING MACHINES', 'DEEPSEA GENERATOR', 'COMPRESSORS',
  //  '32kw CEM7 GENERATORS', 'BUS', 'CAR','TRUCK', 'BIKE', 'AUTO', 'BATTERY','BOBCAT', 'TANKER', 'LOADER', 'DABBAB',
  //  'DUMPER', 'STREET SWEEPER', 'TOWED STREET SWEEPER', 'COMPACTOR','DOUBLE CABIN', 'HOOK LIFT', 'CRANE',
  //  'SMALL TRUCK'
  provider = [
    { sim: 'Idea' },
    { sim: 'Airtel' },
    { sim: 'Vodafone' },
    { sim: 'Bsnl' }
  ];

  createForm() {
    this.fleetLogin = this.formBuilder.group({
      operatorName: ['', Validators.required],
      tagID: [''],
      contact: ['', Validators.required],
      Address1: ['', Validators.required],
      branchlocation: [''],
      // Address2: ['', ],
      city: ['', Validators.required],
      email: ['',],
      country: ['', Validators.required],
      emergencyContact: ['',],
      licence: ['',],
      licenceExpiry: ['',]
    });
  }




  // editForm() {
  //   if (this.value.submit == "available") {
  //     this.fleetLogin = this.formBuilder.group({
  //       operatorName: [this.value.operatorName, Validators.required],
  //       contact: [this.value.telNo, Validators.required],
  //       Address1: [this.value.address, ''],
  //       // Address2: ['', Validators.required ],
  //       city: [this.value.address2, Validators.required],
  //       email: ['', Validators.required],
  //       country: ['',],
  //       emergencyContact: ['',],
  //       licence: ['',],
  //       licenceExpiry: ['',]
  //     });
  //     this.formDisplay = true;

  //     this.editVehType = this.value.vehicleType;
  //     this.editfleet = this.value.alerts;

  //   }
  // }

  getOperators() {
    const url = serverUrl.web + '/login/getPreferences?key=countries&companyId=' + this.companyDetail.companyID;
    this.ajaxService.ajaxGetPerference(url)
      .subscribe(res => {
        this.fleet = res;
       })

  }

  getSelectedvalue(name) {
    if (!this.selectValue.includes(name))
      this.selectValue.push(name);
  }

  submit() {

    if (this.serviceName != "available") {


      var data = {
        "optId": false,
        "operatorId": "",
        "operatorStatus": true,
        "compnyID": this.companyDetail.companyID,
        "branchID": this.companyDetail.branchID,
        "username": this.companyDetail.userId,
        "optName": this.fleetLogin.value.operatorName.trim(),
        "tagID": this.fleetLogin.value.tagID.trim(),
        "cntctNo": this.fleetLogin.value.contact.toString().trim().replace(/ /g, ""),
        "addressLine1": this.fleetLogin.value.Address1.trim(),
        "branchlocation": this.fleetLogin.value.branchlocation.trim(),
        "addressLine2": this.fleetLogin.value.city.trim(),
        "country": this.fleetLogin.value.country,
        "emailId": this.fleetLogin.value.email,
        "emergencyContactName": "",
        "emergencyContactNumber": this.fleetLogin.value.emergencyContact.trim().replace(/ /g, "") + "",
        "licenceNumber": this.fleetLogin.value.licence.toString(),
        "licenceExpiry": this.fleetLogin.value.licenceExpiry,

      }



      Object.keys(data).forEach((key) => (data[key] == null || data[key] == "") && delete data[key])
      data["operatorId"] = "";
      data["optId"] = false;
      const url = serverUrl.web + '/operator/addOperatorInfo';
      this.ajaxService.ajaxPostWithBody(url, data).subscribe(res => {
       // if (res.length > 2) {
       console.log(res);
       
          this.commonService.presentToast('Operator added succesfully');
          this.fleetLogin.reset();
          this.modalController.dismiss();
       // }
      })
    } else if (this.serviceName == "available") {

      var oldValue = {};
      oldValue = {
        "emailId": this.selectValue.includes("email") ? this.value.eMailAddress : undefined,
        "optName": this.selectValue.includes("operatorName") ? this.value.name : undefined,
        "tagID": this.selectValue.includes("TagID") ? this.value.surName : undefined,
        "cntctNo": this.selectValue.includes("contact") ? this.value.telNo : undefined,
        "addressLine1": this.selectValue.includes("Address1") ? this.value.address : undefined,
        "branchlocation": this.selectValue.includes("branchlocation") ? this.value.branchlocation : undefined,
        "addressLine2": this.selectValue.includes("city") ? this.value.city : undefined,
        "country": this.selectValue.includes("country") ? this.value.nationality : undefined,
        "emergencyContactNumber": this.selectValue.includes("emergencyContact") ? this.value.emergencyContactNo : undefined,
        "licenceNumber": this.selectValue.includes("licence") ? this.value.licenseNo : undefined,
        "licenceExpiry": this.selectValue.includes("licenceExpiry") ? this.value.licenseExpiry : undefined,
        "macIp": ""
      }

      var details = {
        "optId": true,
        "operatorId": this.value.operatorID,
        "operatorStatus": true,
        "compnyID": this.companyDetail.companyID,
        "branchID": this.companyDetail.branchID,
        "username": this.companyDetail.userId,
         "optName": this.fleetLogin.value.operatorName.trim(), 
         "tagID": this.fleetLogin.value.tagID ? this.fleetLogin.value.tagID.trim() : "",
         "cntctNo": isNaN(this.fleetLogin.value.contact) ? this.fleetLogin.value.contact.toString().trim().replace(/ /g, "") : this.fleetLogin.value.contact.toString().trim().replace(/ /g, ""),
        "addressLine1": (this.fleetLogin.value.Address1.trim()) ? this.fleetLogin.value.Address1.trim() : "",
        "branchlocation": (this.fleetLogin.value.branchlocation) ? this.fleetLogin.value.branchlocation.trim() : "",
        "addressLine2": (this.fleetLogin.value.city.trim()) ? this.fleetLogin.value.city.trim() : "",
        "country": (this.fleetLogin.value.country) ? this.fleetLogin.value.country : "",
         "emailId": (this.fleetLogin.value.email) ? this.fleetLogin.value.email : "",
        "emergencyContactName": "",
        //"emergencyContactNumber": (this.fleetLogin.value.emergencyContact ) ? this.fleetLogin.value.emergencyContact + "" : "",
        "emergencyContactNumber": this.fleetLogin.value.emergencyContact ? this.fleetLogin.value.emergencyContact.toString().trim().replace(/ /g, "") : "",

         "licenceNumber": (this.fleetLogin.value.licence) ? this.fleetLogin.value.licence.toString() : "",
         "licenceExpiry": this.fleetLogin.value.licenceExpiry ? this.fleetLogin.value.licenceExpiry:"",
         "oldValue":JSON.stringify(oldValue)

      }
     
      Object.keys(details).forEach((key) => (details[key] == null || details[key] == "") && delete details[key])
      const url = serverUrl.web + '/operator/updateOperatorInfo';
      this.ajaxService.ajaxPostWithString(url, details).subscribe(res => {
        if (JSON.stringify(res) == '{}') {
          this.commonService.presentToast('Updated succesfully');
          this.modalController.dismiss();
        } else {
          this.commonService.presentToast('Operator is already associated');

        }
      })
    }
    // else{
    //   const deleteData={"tagID":"OPjana6719","compnyID":"jana","branchID":"jana","companyNme":"jana-ca"}
    //   const url=serverUrl.web + '/api/vts/company/operator';
    //   this.ajaxService.ajaxDeleteWithBody(url, data).subscribe(res =>{
    //   })
    // }
  }

  async deleteSelectedOperator() {
    if (this.value) {
      const alert = await this.alertController.create({
        header: 'Delete',
        backdropDismiss: false,
        message: "Are you sure you want to delete!",
        buttons: [{
          text: 'Cancel',
          role: 'cancel',
          handler: data => {
          }
        },
        {
          text: 'Ok',
          handler: data => {
            const deleteData = { "tagID": this.value.operatorID, "compnyID": this.companyDetail.companyID, "branchID": this.companyDetail.branchID, "companyNme": this.companyDetail.userId }
            const url = serverUrl.web + '/operator/deleteOperatorInfo?operatorID=' + this.value.operatorID;
            this.ajaxService.ajaxDeleteWithString(url).subscribe(res => {
              if (res.error.text == "Operator is already Associated") {
                this.commonService.presentToast("Associated operator cannot be deleted")

                this.getDatas();
              } else if (res.error.text == "success") {
                this.commonService.presentToast("Deleted successfully")
                this.getDatas();
                this.modalController.dismiss()
              }

            })
          }
        }]
      });
      await alert.present();
    }
  }
  getDatas() {
    const companyDetail = {
      branchID: localStorage.getItem('corpId'),
      companyID: localStorage.getItem('corpId'),
      userId: localStorage.getItem('userName')
    }

    var datas = { "compnyID": companyDetail.companyID, "branchID": companyDetail.branchID, "companyNme": companyDetail.userId + '' }
    var url2 = serverUrl.web + '/operator/operatorinfo?companyId=' + companyDetail.companyID + '&branchId=' + companyDetail.branchID;
    this.ajaxService.ajaxPostMethodWithoutData(url2).subscribe(res => {
      var detail = res;

    })

  }

  ngOnInit() {
    this.companyDetail = {
      branchID: localStorage.getItem('corpId'),
      companyID: localStorage.getItem('corpId'),
      userId: localStorage.getItem('userName')
    }
    let localMainMenu = JSON.parse(localStorage.mainMenu)
    this.isDeleteShow = localMainMenu.includes("Delete")
    this.createForm();
    this.getOperators();
    // this.editForm();
    this.companyDetail = {
      branchID: localStorage.getItem('corpId'),
      companyID: localStorage.getItem('corpId'),
      userId: localStorage.getItem('userName')
    }

    this.selectedCompany = localStorage.getItem('corpId');
    if (this.value) {
      if (this.value.submit == "available") {
        this.hideSerialNo = false;
        this.serviceName = "available";
        this.vin = this.value.vin;

        this.fleetLogin.patchValue({
          operatorName: this.value.name,
          tagID: this.value.surName,
          contact: this.value.telNo,
          Address1: this.value.address,
          branchlocation: this.value.branchlocation,
          city: this.value.address2,
          email: this.value.eMailAddress,
          country: this.value.nationality,
          emergencyContact: this.value.emergencyContactNo,
          licence: this.value.licenseNo,
          licenceExpiry: this.value.licenseExpiry,

        });


      }
    }
  }

}
