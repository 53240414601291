import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { ModalController, NavController, NavParams } from '@ionic/angular';
import { AjaxService } from 'src/app/services/ajax.service';
import { CommonService } from 'src/app/services/common.service';
import { serverUrl } from 'src/environments/environment';
@Component({
  selector: 'app-sim-device-modal',
  templateUrl: './sim-device-modal.component.html',
  styleUrls: ['./sim-device-modal.component.scss'],
})
export class SimDeviceModalComponent implements OnInit {
  sim: FormGroup;
  @Input() page;
  @Input() mode;
  @Input() selectedRow;
  simProvider = ["Airtel", "Aircel", "Idea", "Vodafone"];
  simPlan = ["Postpaid", "Prepaid"]
  imei: FormGroup;
  device: any;
  manufacture: unknown[];
  simNo: AbstractControl;
  model: any = [];
  yesNo: any = ["Yes", "No"];
  simCardManufacturer: any = { 'manufacturer': [], 'model': [] };
  manuAndModel: any;
  simCardNo: any = [];
  selectValue: any = [];
  editOnly;
  manufactureName: any;
  constructor(
    private formBuilder: FormBuilder,
    private ajaxService: AjaxService,
    private commonService: CommonService,
    private modalController: ModalController,
    public navCtrl: NavController,
    public navParams: NavParams,
  ) { }
  validateNo(e: any): boolean {
    return this.commonService.validateNo(e);
  }
  addSimCard() {
    if (this.mode == 'Add') {
      if (isNaN(this.sim.value.simNo.trim().replace(/ /g, ""))) {
        this.commonService.presentToast("Please fill the valid details");
        return "";
      }
      let url = serverUrl.web + "/DCFramework/checkSimAvailabilty?simCard=" + this.sim.value.simNo.trim().replace(/ /g, "") + "&corpId=" + localStorage.getItem('corpId');
      this.ajaxService.ajaxGetWithString(url)
        .subscribe(res => {
          if (res == "notavailable") {
            if (this.sim.value.simNo) {
              url = serverUrl.web + "/DCFramework/addSimCard"
              const data = {
                "serialNo": this.sim.value.simNo.trim().replace(/ /g, ""),
                "pukCode": this.sim.value.pukCode.trim().replace(/ /g, ""),
                "supportNo": this.sim.value.supportNo.trim().replace(/ /g, ""),
                "purchaseDt": this.sim.value.purchaseDate,
                "pinCode": (!this.sim.value.pinCode) ? "0" : this.sim.value.pinCode.trim().replace(/ /g, ""),
                "servcePrvdr": this.sim.value.provider ? this.sim.value.provider : "Unknown",
                "selectPln": this.sim.value.plan ? this.sim.value.plan : "Unknown",
                "compnyID": localStorage.corpId,
                "branchID": localStorage.corpId,
                "emailID": localStorage.userName
              }
              this.ajaxService.ajaxPostWithString(url, data)
                .subscribe(res => {
                  if (res == "successfully inserted-->") {
                    this.modalController.dismiss()
                    this.commonService.presentToast("Sim added successfully")
                  } else {
                    this.commonService.presentToast("Issue in creation")
                  }
                })
            } else
              this.commonService.presentToast("please Enter Mandatory Fields");
          }
          else if (res == "key missing") {
            this.commonService.presentToast("Something went wrong ,Please reload your page");
          } else {
            this.commonService.presentToast("Sim card already available")
          }
        })
    } else if (this.mode == 'edit') {
      if (this.selectedRow.simCardNo ? true : false && this.sim.value.pinCode ? true : false) {
        var oldValue = {};
        oldValue = {
          "serialNo": this.selectValue.includes("simNo") ? this.selectedRow.simCardNo : undefined,
          "pukCode": this.selectValue.includes("pukCode") ? this.selectedRow.pukCode : undefined,
          "supportNo": this.selectValue.includes("supportNo") ? this.selectedRow.supportNo : undefined,
          "purchaseDt": this.selectValue.includes("purchaseDate") ? this.selectedRow.DOP : undefined,
          "pinCode": this.selectValue.includes("pinCode") ? this.selectedRow.pinCode : undefined,
          "servcePrvdr": this.selectValue.includes("provider") ? this.selectedRow.provider : undefined,
          "selectPln": this.selectValue.includes("plan") ? this.selectedRow.plan : undefined,
          "macIp": ""
        }

        let url = serverUrl.web + "/DCFramework/addSimCard"
        const data = {
          "serialNo": this.selectedRow.simCardNo,
          "pukCode": this.sim.value.pukCode,
          "supportNo": this.sim.value.supportNo,
          "purchaseDt": this.sim.value.purchaseDate,
          "pinCode": (!this.sim.value.pinCode) ? "0" : this.sim.value.pinCode,
          "servcePrvdr": this.sim.value.provider ? this.sim.value.provider : "Unknown",
          "selectPln": this.sim.value.plan ? this.sim.value.plan : "Unknown",
          "compnyID": localStorage.corpId,
          "branchID": localStorage.corpId,
          "emailID": localStorage.userName,
          "mode": "edit",
          "oldValue": JSON.stringify(oldValue)
        }
        this.ajaxService.ajaxPostWithString(url, data)
          .subscribe(res => {
            if (res == "successfully inserted-->") {
              this.modalController.dismiss()
              this.commonService.presentToast("Sim updated successfully")
            } else {
              this.commonService.presentToast("Issue in creation")
            }
          })
      } else
        this.commonService.presentToast("Failed");
    } else {
      this.commonService.presentToast("Sim card already available")
    }
  }
  getSelectedvalue(name) {
    if (!this.selectValue.includes(name))
      this.selectValue.push(name);
  }

  addDevice() {

    if (this.mode == 'Add') {
      let devicedata = this.device.value;
      console.log("👽👽👽👽", devicedata);

      let purchaseDate = "";
      let defaultWarantyDate = "";

      var currentTime = new Date();
      var month = (currentTime.getMonth() + 1).toString().length > 1 ? (currentTime.getMonth() + 1) : "0" + (currentTime.getMonth() + 1);
      var day = currentTime.getDate().toString().length > 1 ? currentTime.getDate() : "0" + currentTime.getDate();
      var year = currentTime.getFullYear();
      if (!this.device.value.purchaseDate) {
        var currentdate = year + "-" + month + "-" + day;
        purchaseDate = currentdate;
      } else {
        purchaseDate = this.device.value.purchaseDate
      }
      if (!this.device.value.warrentyDate && !this.device.value.purchaseDate) {
        var year1 = currentTime.getFullYear() + 1;
        var currentdate1 = year1 + "-" + month + "-" + day;
        defaultWarantyDate = currentdate1;
      } else if (!this.device.value.warrentyDate) {
        defaultWarantyDate = parseInt(purchaseDate.split("-")[0]) + parseInt(JSON.parse(JSON.parse(localStorage.loginData)[1].applicationSettings).DeviceExpiryYearCount) + '-' + purchaseDate.split("-")[1] + '-' + purchaseDate.split("-")[2]
      } else {
        defaultWarantyDate = this.device.value.warrentyDate;
      }

      if (devicedata.imeiNo != "" && devicedata.manufacture != null && devicedata.simNo != null && devicedata.model != null) {
        if (isNaN(devicedata.imeiNo.trim().replace(/ /g, "") || devicedata.simNo.trim().replace(/ /g, ""))) {
          this.commonService.presentToast("Please fill the valid details");
          return "";
        }
        let url = serverUrl.web + "/DCFramework/getimeiNoValidation?imeiNo=" + devicedata.imeiNo.trim().replace(/ /g, "") + "&corpId=" + localStorage.getItem('corpId');
        this.ajaxService.ajaxGetWithString(url)
          .subscribe(res => {
            if (res == "notavailable") {
              url = serverUrl.web + "/DCFramework/getAddDeviceDetails"
              console.log(devicedata.yesOrNo);

              const data = {
                "IMEINo": devicedata.imeiNo.trim().replace(/ /g, ""),
                "ManufacturerName": devicedata.manufacture,
                "simNo": devicedata.simNo.trim().replace(/ /g, ""),
                "Model": devicedata.model,
                "DateOfPurchase": purchaseDate,
                "DefaultWarnty": defaultWarantyDate,
                "AddtnlWarrnty": devicedata.aditionalDate != null ? devicedata.aditionalDate : "",
                "deviceType": devicedata.yesOrNo == "No" ? "MainDevice" : "SubDevice",
                "serialNo": devicedata.serialNo != null ? devicedata.serialNo : "",
                "compnyID": localStorage.corpId,
                "branchID": localStorage.corpId,
                "emailID": localStorage.userName,
                "setDissociate": 0
              }
              this.ajaxService.ajaxPostWithString(url, data)
                .subscribe(res => {
                  if (JSON.parse(res).deviceStatus == "persisted") {
                    this.modalController.dismiss()
                    this.commonService.presentToast("Imei added successfully")
                  } else {
                    this.commonService.presentToast("Issue in creation")
                  }
                })
            }
            else if (res == "key missing") {
              this.commonService.presentToast("Something went wrong ,Please reload your page");
            } else {
              this.commonService.presentToast(res)
            }
          })
      } else {
        this.commonService.presentToast("please Enter Mandatory Fields")
      }
    } else {
      let devicedata = this.device.value;
      if (devicedata.imeiNo != "" && devicedata.manufacture != null && devicedata.simNo != null && devicedata.model != null) {

        let url = serverUrl.web + "/DCFramework/getEditDeviceDetails"
        var oldValue = {};
        oldValue = {
          "IMEINo": this.selectValue.includes("Imei no") ? this.selectedRow["IMEI Number"] : undefined,
          "ManufacturerName": this.selectValue.includes("Manufacturer Name") ? this.selectedRow["Manufacturer"] : undefined,
          "simNo": this.selectValue.includes("Sim Card Number") ? this.selectedRow["SIM Card Number"] : undefined,
          "Model": this.selectValue.includes("Model") ? this.selectedRow["Model"] : undefined,
          "DateOfPurchase": this.selectValue.includes("Date of purchase") ? this.selectedRow["Date of Purchase"] : undefined,
          "DefaultWarnty": this.selectValue.includes("Warrenty date") ? this.selectedRow["Warranty Expiry Date"] : undefined,
          "macIp": ""
        }


        const data = {
          "IMEINo": devicedata.imeiNo.trim().replace(/ /g, ""),
          "ManufacturerName": devicedata.manufacture,
          "simNo": devicedata.simNo.trim().replace(/ /g, ""),
          "Model": devicedata.model,
          "DateOfPurchase": devicedata.purchaseDate,
          "DefaultWarnty": devicedata.warrentyDate,
          "deviceType": devicedata.yesOrNo == "No" ? "MainDevice" : "SubDevice",
          "AddtnlWarrnty": devicedata.aditionalDate != null ? devicedata.aditionalDate : "",
          "serialNo": devicedata.serialNo != null ? devicedata.serialNo : "",
          "compnyID": localStorage.corpId,
          "branchID": localStorage.corpId,
          "emailID": localStorage.userName,
          "setDissociate": 1,
          "oldsimNo": this.selectedRow['SIM Card Number'],
          "oldValue": JSON.stringify(oldValue),

        }
        this.ajaxService.ajaxPostWithString(url, data)
          .subscribe(res => {
            if (JSON.parse(res).deviceStatus == "updated") {
              this.modalController.dismiss()
              this.commonService.presentToast("device updated successfully")
            } else {
              this.commonService.presentToast("Issue in creation")
            }
          })


      } else {
        this.commonService.presentToast("please Enter Mandatory Fields")
      }
    }

  }

  changeModel(data) {
    this.model = this.manuAndModel[data.detail['value']]
  }


  addManuAndModel() {
    const url = serverUrl.web + "/DCFramework/getDeviceModelsAndManufactureName?compId=" + localStorage.corpId
    this.ajaxService.ajaxGet(url)
      .subscribe(res => {

        this.manufacture = Object.keys(res);
        this.manuAndModel = res;

      })
  }
  getUnusedSimCardnumber() {
    const url = serverUrl.web + "/DCFramework/getUnusedSimCard?compId=" + localStorage.corpId
    this.ajaxService.ajaxGet(url)
      .subscribe(res => {
        this.simCardNo = [];
        let simcarddetails = res;
        for (let sim in simcarddetails) {
          this.simCardNo.push(simcarddetails[sim].simCardNo);
        }

        if (this.mode == "edit") {
          this.simCardNo.push(this.selectedRow['SIM Card Number'])
        }

      })
  }


  getSimcardModel() {
    const url = serverUrl.web + "/DCFramework/getSimCardModels"
    this.ajaxService.ajaxGet(url)
      .subscribe(res => {
        this.simCardManufacturer = res
      })
  }


  async openSimCardMode() {
    const model = await this.modalController.create({
      component: SimDeviceModalComponent,
      cssClass: 'user_feature',
      componentProps: {
        page: "Sim",
        mode: "Add"
      }
    })
    model.onDidDismiss().then(() => {
      this.getUnusedSimCardnumber()
    })
    await model.present();
  }


  ngOnInit() {

    this.commonService.presentLoader()
    this.addManuAndModel()
    this.getSimcardModel()
    this.getUnusedSimCardnumber()
    console.log(this.selectedRow);

    if (this.page == "Sim") {
      this.sim = this.formBuilder.group({
        simNo: ['', Validators.required],
        pukCode: [''],
        supportNo: [''],
        purchaseDate: [''],
        pinCode: [''],
        provider: [''],
        plan: [""],
      });
      if (this.mode == "edit") {
        console.log("this.selectrow", this.selectedRow);

        this.sim.patchValue({
          simNo: this.selectedRow.simCardNo,
          pukCode: this.selectedRow.pukCode,
          supportNo: this.selectedRow.supportNo,
          purchaseDate: this.selectedRow.DOP,
          pinCode: (this.selectedRow.pinCode == "0") ? "" : this.selectedRow.pinCode,
          provider: this.selectedRow.provider,
          plan: this.selectedRow.plan
        })
        this.sim.controls['simNo'].disable();

      }
      setTimeout(() => this.commonService.dismissLoader(), 1000)
    } else if (this.page == "Device") {
      this.device = new FormGroup({
        imeiNo: new FormControl('', Validators.minLength(2)),
        manufacture: new FormControl(),
        simNo: new FormControl(),
        model: new FormControl(),
        dcimeino: new FormControl(),
        dcsimno: new FormControl(),
        purchaseDate: new FormControl(),
        warrentyDate: new FormControl(),
        aditionalDate: new FormControl(),
        serialNo: new FormControl(),
        yesOrNo: new FormControl()

      });
      if (this.mode == "edit") {
        setTimeout(() => {
          this.device.patchValue({
            imeiNo: this.selectedRow['IMEI Number'],
            manufacture: this.selectedRow['Manufacturer'],
            simNo: this.selectedRow['SIM Card Number'],
            model: this.selectedRow['Model'],
            purchaseDate: this.selectedRow['Date of Purchase'],
            warrentyDate: this.selectedRow['Warranty Expiry Date'],
            aditionalDate: this.selectedRow['additionalWarranty'],
            serialNo: this.selectedRow['serialNumber'],
            yesOrNo: this.selectedRow["deviceType"] == "SubDevice" ? "Yes" : "No"
          })

        }, 1000)
        setTimeout(() => {
          this.device.patchValue({ manufacture: this.selectedRow['Manufacturer'], });
        }, 600);

        setTimeout(() => {
          this.device.patchValue({ model: this.selectedRow['Model'], });
        }, 900);
        setTimeout(() => {
          this.device.patchValue({ simNo: this.selectedRow['SIM Card Number'], });
          this.commonService.dismissLoader()
        }, 1100);
      }
      setTimeout(() => {
        this.device.patchValue({ yesOrNo: "No" });
      }, 500);
      if (this.mode != "edit")
        setTimeout(() => {
          this.commonService.dismissLoader()
        }, 1000);

    }
  }
}

