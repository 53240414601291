import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import * as Highcharts from 'highcharts';
import { AjaxService } from 'src/app/services/ajax.service';
import { serverUrl } from 'src/environments/environment';

@Component({
  selector: 'app-battery-chart',
  templateUrl: './battery-chart.component.html',
  styleUrls: ['./battery-chart.component.scss'],
})
export class BatteryChartComponent implements OnInit, OnChanges {
  batteryValues: Array<any> = [];
  show: Boolean = false;
  @Input() selVin;
  constructor(private api: AjaxService) { }
  ngOnChanges(changes: SimpleChanges): void {
    console.log("selVin", this.selVin);
    this.fetchData(this.selVin)
  }
  today = new Date();
  selectedDate=this.today.toISOString().split('T')[0]; 

  filterDataByDate() {
    console.log('Filtering data for:', this.selectedDate);
    this.fetchData(this.selVin);
  }
  
  fetchData(vin) {
    let url = serverUrl.web + "/report/socchartdata";
    // let currentDate = new Date().toISOString().split('T')[0];
    let json = {
      "vin": vin,//"demo2672",
      "fromDate": `${this.selectedDate} 00:00:00`,
      "toDate": `${this.selectedDate} 23:59:59`
    }

    this.api.ajaxPostWithBody(url, json).subscribe(res => {
      console.log("res", res);
      this.batteryValues = res.map(el => {
        let dateObj = new Date(el.eventtimestamp);
        let trimmeddate = `${dateObj.toLocaleString('en-us', { month: 'short' })}-${dateObj.getDate()} ${(dateObj.getHours() % 12 || 12).toString().padStart(2, '0')
          }:${dateObj.getMinutes().toString().padStart(2, '0')}`;
        let totalSeconds = dateObj.getHours() * 3600 + dateObj.getMinutes() * 60 + dateObj.getSeconds() + dateObj.getMilliseconds() / 1000;

        return [parseInt(el.percentage.split("%")[0]), totalSeconds, el.volt, trimmeddate]; // Ensure percentage is a number
      });
      this.batteryValues.sort((a, b) => a[0] - b[0]);
      this.batteryValues.sort((a, b) => a[1] - b[1]);

      console.log(this.batteryValues);

      this.show = true;
      this.renderChart()
    })
  }
  renderChart() {
    let options: Highcharts.Options = {
      chart: {
        type: 'spline',
        inverted: false,
        zooming: {
          type: 'x',
          singleTouch: true
      }
      },
      title: {
        text: '🔋 Battery Performance Analysis'
      },
      
      subtitle: {
        text: 'Voltage and Charge Trends Over Time'
      },
      xAxis: {
        type: 'category',
        reversed: false,
        title: {
          text: 'Time (HH:MM)'
        },
        labels: {
          formatter: function () {
            let seconds = this.value as number;
            let hours = Math.floor(seconds / 3600);
            let minutes = Math.floor((seconds % 3600) / 60);
            let secs = Math.floor(seconds % 60);
            return `${hours}:${minutes.toString().padStart(2, '0')}`;
          }
        },
        maxPadding: 0.05,
 tickInterval: 7200,
        min: 0,
        showLastLabel: true
      },
      yAxis: {
        title: {
          text: 'SOC'
        },
        labels: {
          format: '{value}%',
          style: {
            fontSize: '12px'
          }
        },
        min: 0, max: 100, tickInterval: 10,
      }
      ,
      legend: {
        enabled: false
      },
      tooltip: {
        headerFormat: '<b>{series.name}</b><br/>',
        formatter: function () {
          return `<b>${this.series.name} : ${this.point["volt"]} <br/> 
              <b>Time : ${this.point["time"]} <br/>`;
        },
        style: {
          fontSize: '10px',  
        },
        borderColor: '#a68c00',
        borderWidth: 1,
      },

      credits: { enabled: false },

      plotOptions: {
        spline: {
          marker: {
            enabled: true,
            radius: 1,
            symbol: 'circle',
            fillColor: '#000000',
            lineWidth: 2,
            lineColor: '#000000'
          }
        }
      },
      series: [{
        type: 'spline',
        name: 'Voltage',
        color: 'transparent',
        lineWidth: 0,
        marker: {
          enabled: true,
          symbol: 'diamond',
          lineColor: "#ffd700",
          radius: 5,
          fillColor: '#ffd700'
        },
        data: this.batteryValues.map(entry => ({
          x: entry[1],
          y: entry[0],
          volt: entry[2],
          time: entry[3]
        }))

      }]
    };

    setTimeout(() => {
      Highcharts.chart('fuelchart', options);
    }, 1000);
  }

  ngOnInit() {
    // this.fetchData()
  }

}
