import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { app, languageInitializer, serverUrl, storageVariable } from 'src/environments/environment';
import { AjaxService } from 'src/app/services/ajax.service';
import { Platform } from '@ionic/angular';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-gridview-card',
  templateUrl: './gridview-card.component.html',
  styleUrls: ['./gridview-card.component.scss'],
})
export class GridviewCardComponent implements OnInit, OnChanges {
  arabicLang = { "0": "٠", "1": "١", "2": "٢", "3": "٣", "4": "٤", "5": "٥", "6": "٦", "7": "٧", "8": "٨", "9": "٩", "10": "١٠" };
  @Input() commonData: any;
  @Input() pageName: any;
  @Input() newData: any;
  @Input() temperatureView;
  directory = languageInitializer.directory
  entryPoint: any;
  data;
  chmShow = true;
  color = {
    'Idle': '#1f5baa',
    'Running': '#1eb15d',
    'Stop': '#FF0000',
    'Yet_to_transmit': '#7d410f',
    'No Transmission': '#000000',
    'Online': '#00E1BC',
    'Overspeed': '#f28918',
    'DoorOpen': '#FF851B',
    'HighTemp': '#FF0000',
    "PowerFail": '#412525',
    'Geofence': '#1f5baa',
    "Good": "#1eb15d",
    'Towed': '#ec268f',
    'StandBy': '#f4d642',
    "Working": "#1eb15d",
    "Out_Of_Zone": "#FF0000",
    "OnLine": "#00008b",
    "GSMLost": "#c200b8",
    "BatteryDrain": "#00aef3",
    "PowerCut": "#ff0000",
    "Movement": "#1eb15d",
    "LowBattery": "#fba300",

  }
  iconColor = {
    'ON': "green",
    'OFF': "red",
    'On': "green",
    'Off': 'red',
    'null': '#838496',
    "1": "green",
    '0': "red",
    'undefined': "#838496"
  }
  seperateUrlLogin: boolean = false;
  constructor(
    private ajaxService: AjaxService,
    private platform: Platform,
    private commonService: CommonService
  ) { }

  ngOnChanges(): void {
    // console.log("this.commonData",this.commonData);

    this.data = this.commonData;

    let Current_hour_meter_Value;

    if (this.data.engineRunTime)
      Current_hour_meter_Value = (this.data.engineRunTime != undefined ? (((parseInt(this.data.engineRunTime
      ) / 60) / 60).toFixed(0).toString()
        + ':' + parseInt(("0" + ((parseInt(this.data.engineRunTime) / 60) % 60)).slice(-2))) : 0);
    else {
      let sec: any = parseInt(this.data.totalRuntime) * 60;
      let hours: any = Math.floor(sec / 3600);
      (hours >= 1) ? sec = sec - (hours * 3600) : hours = '00';
      let min: any = Math.floor(sec / 60);
      (min >= 1) ? sec = sec - (min * 60)
        : min = '00';
      (sec < 1) ? sec = '00' : void 0;
      (min.toString().length == 1) ? min = '0'
        + min : void 0;
      (sec.toString().length == 1) ? sec = '0'
        + sec : void 0;
      Current_hour_meter_Value = hours + ':' + min;
    }

    this.data["CurrentHourNew"] = Current_hour_meter_Value;
    if (this.data.timeDifference == null) {
      this.data.timeDifference = '00:00';

    }

    if (this.data.hourMeterValue == null) {
      this.data.hourMeterValue = '--:--';
    }
    else {
      this.data.hourMeterValue;
    }

    this.temperatureView = this.temperatureView
    if (this.data['odometer'] != 0 || this.data['odometer'] != '0') {
      this.data['odometer'] = parseFloat(this.data['odometer']).toFixed(1)
    }
    if (this.data['icon'] == "REFRIGERATOR") {
      this.data.gsmSignalStrength = (parseInt(this.data.gsmSignalStrength) / 25).toFixed().toString();
    }
    if (this.data['model'] == "AIS1402A" || this.data['model'] == "AIS1401A") {
      this.data.gsmSignalStrength = (parseInt(this.data.gsmSignalStrength) / 6).toFixed().toString();
    }
    if (this.data['model'] == "GE") {
      if (this.data.gsmSignalStrength > 10 || this.data.gsmSignalStrength < 15)
        this.data.gsmSignalStrength = 0
      if (this.data.gsmSignalStrength > 10 || this.data.gsmSignalStrength < 15)
        this.data.gsmSignalStrength = 3
      if (this.data.gsmSignalStrength > 15)
        this.data.gsmSignalStrength = 4
    }
    if (!this.data.location) {
      this.getAddress(this.data.latitude, this.data.longitude)
    }
  }
  isNumber(value: any): boolean {
    return !isNaN(parseFloat(value)) && value.toString().includes('.');
  }


  getIconColor(powerSupplyVoltage: any): string {

    if (!isNaN(parseFloat(powerSupplyVoltage)) && powerSupplyVoltage.toString().includes('.')) {
      return 'green'; // Decimal number case
    }

    return this.iconColor[powerSupplyVoltage]
  }
  getShiftDetails(shift, split) {
    let shDetails = ""
    if (!shift)
      return "---"
    for (let i = 0; i < shift.split(split).length; i++) {
      if (shift.split(split)[i]) {
        shDetails += shift.split(split)[i].split("#")[1];
        if (i != shift.split(split).length - 2)
          shDetails += ", "
      }
    }

    return shDetails;
  }
  getSatelliteIconColor(v_tags) {
    if (v_tags == null || v_tags == undefined) {
      return ' grey';
    }
    else if (v_tags >= 0 && v_tags <= 3) {
      return 'red';
    } else if (v_tags > 3 && v_tags <= 6) {
      return 'yellow';
    } else if (v_tags > 6) {
      return 'green';
    }
  }
  getgsmSignalStrengthcolour(gsmSignalStrength) {
    if (gsmSignalStrength == null || gsmSignalStrength == undefined) {
      return ' grey';
    }
    const strength = Number(gsmSignalStrength);

    if (strength >= 0 && strength <= 10) {
      return 'red';
    } else if (strength > 10 && strength <= 20) {
      return 'yellow';
    } else if (strength > 20) {
      return 'green';
    }
  }
  getAddress(lat, lng) {
    // const url = serverUrl.web+"/api/vts/company/latlngtoaddress/"+lat+"/"+lng+"/"+localStorage.corpId;
    // this.ajaxService.ajaxGetObject(url)
    // .subscribe( res => {
    //   if(JSON.stringify(res) == "{}"){
    //     this.data.location= this.data.latitude+','+this.data.longitude;
    //   }else{
    //     this.data.location= res;
    //   }

    // });
  }
  getTempStatusColor(currTemp, minTemp, maxTemp) {
    let absoluteDifference = 3;
    if ((Math.abs(currTemp - minTemp) <= absoluteDifference) || (Math.abs(currTemp - maxTemp) <= absoluteDifference)) {
      return 'orange';
    } else if ((parseFloat(currTemp) < parseFloat(minTemp)) || (parseFloat(currTemp) > parseFloat(maxTemp))) {
      return 'red';
    } else {
      return 'green'
    }
  }
  getModel(data) {
    var data = this.commonService.getModel(data)
    return data;
  }
  getArabicLetters(letter) {

    return this.commonService.numberToArabic(letter)
  }


  splitNameArabic(name) {
    return this.commonService.splitNameArabic(name, this.directory)
  }
  ngOnInit() {
    if (localStorage.corpId == "latis") {
      this.chmShow = false;
    }
    if (sessionStorage.seprateLogin == 'true') {
      this.data = JSON.parse(localStorage.seperateLiveData);
      this.seperateUrlLogin = true;
      this.data['speed'] = this.commonData['speed']
    } else {
      this.seperateUrlLogin = false;
      this.data = this.commonData;
    }
    this.commonService.updateLanguageDir
      .subscribe(res => {
        this.directory = res == "" ? this.directory : res
      })
    this.entryPoint = app.entryPoint;
  }

}
