import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { AlertController, ModalController } from '@ionic/angular';
import { CommonService } from 'src/app/services/common.service';
import { AjaxService } from 'src/app/services/ajax.service';
import { jqxComboBoxComponent } from 'jqwidgets-ng/jqxcombobox';
import { app, languageInitializer, serverUrl, storageVariable } from 'src/environments/environment';
import { ShiftDetailsModelComponent } from '../../shift-details/shift-details-model/shift-details-model.component';
import { SimDeviceModalComponent } from '../../../components/sim-device-modal/sim-device-modal.component'
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-employees-model',
  templateUrl: './employees-model.component.html',
  styleUrls: ['./employees-model.component.scss'],
})
export class EmployeesModelComponent implements OnInit {
  @Input() mode;
  @Input() selectedRow;
  imei: any = [];
  vin: any;
  freeformFenceList: any;
  shiftNames: any = [];
  employeesType: any = [];
  directory = languageInitializer.directory
  fleetLogin: any;
  debuggmode = [true, false]
  freeFromObj: any = {};
  freeFromObj2: any = [];
  supervisorGrid: any = [];
  dataAdapter: any;
  source: { localdata: any; };
  vehicleAssFleets: any;
  restDayGrid = [{ "id": "None", "name": "None" }, { "id": "Sunday", "name": "Sunday" }, { "id": "Monday", "name": "Monday" }, { "id": "Tuesday", "name": "Tuesday" }, { "id": "Wednesday", "name": "Wednesday" },
  { "id": "Thursday", "name": "Thursday" }, { "id": "Friday", "name": "Friday" }, { "id": "Saturday", "name": "Saturday" }];
  columns: { text: string; datafield: string; cellsrenderer: (row: number, column: any, value: string) => string; }[];
  renderer: (row: number, column: any, value: string) => string;
  @ViewChild('employeesTypeComobox', { static: false }) employeesTypeComobox: jqxComboBoxComponent;
  @ViewChild('restDayComobox', { static: false }) restDayComobox: jqxComboBoxComponent;
  @ViewChild('supervisorComobox', { static: false }) supervisorComobox: jqxComboBoxComponent;
  @ViewChild('WorkzoneComobox', { static: false }) WorkzoneComobox: jqxComboBoxComponent;
  @ViewChild('shiftNameComobox', { static: false }) shiftNameComobox: jqxComboBoxComponent;
  @ViewChild('Debuggingmode', { static: false }) Debuggingmode: jqxComboBoxComponent;
  debugmode: boolean = false;
  editedDataArray: any = [];

  constructor(
    public formBuilder: FormBuilder,
    private commonService: CommonService,
    private modalController: ModalController,
    private ajaxService: AjaxService,
    private trans: TranslateService,
  ) { }


  getImei() {
    const url = serverUrl.web + '/VehicleController/UnUsedDevice';
    this.ajaxService.ajaxPostWithBody(url, { "companyID": localStorage.corpId })
      .subscribe(res => {
        this.imei = res;
      })
  }

  getGeofenceData() {
    let url = serverUrl.web + "/Alert/GeozoneDatas?data=" + localStorage.corpId;
    this.ajaxService.ajaxGetJson(url).subscribe((res) => {
      if (res["Freeform"]) {
        this.freeFromObj = [];
        this.freeformFenceList = Object.values(res["Freeform"])
        for (let freefrom of this.freeformFenceList) {
          let freeValue = {};
          freeValue["id"] = freefrom.id.split("-")[0]
          freeValue["name"] = freefrom.name;
          this.freeFromObj.push(freeValue)
        }
        console.log("this.freeFromObj", this.freeFromObj);
        this.freeFromObj2 = [{ label: "Select All", value: "All" }, ...this.freeFromObj]

        console.log("this.freeFromObj2", this.freeFromObj2);

        if (this.mode == "edit")
          setTimeout(res => {
            for (let g of this.selectedRow["WorkZone"].split(","))
              this.WorkzoneComobox.checkItem(g);
          }, 100)
      }
    });
  }

  generateVin() {
    const url = serverUrl.web + '/site/generateVin?companyID=' + localStorage.getItem('corpId');
    this.ajaxService.ajaxGetWithString(url)
      .subscribe(res => {
        this.vin = res;
      })
  }

  getEmployeeType() {
    const url = serverUrl.web + '/login/getPreferences?key=EmployeeTypes&companyId=' + localStorage.corpId;
    this.ajaxService.ajaxGetPerference(url)
      .subscribe(res => {
        this.employeesType = res;
      })
  }

  getVehicleAssociatedFleetManager() {
    const url = serverUrl.web + "/user/vehicleAssociatedFleetmanagers?companyId=" + localStorage.corpId + "&branchId=" + localStorage.corpId
    this.ajaxService.ajaxGet(url)
      .subscribe(res => {
        this.vehicleAssFleets = res
      })
  }

  getFleetManager() {
    const url = serverUrl.web + '/user/fleetmanagers?companyId=' + localStorage.getItem('corpId') + '&branchId=' + localStorage.getItem('corpId');
    this.ajaxService.ajaxGet(url)
      .subscribe(res => {
        this.supervisorGrid = [{ label: "Select All", value: "All" }, ...res];
        if (this.mode == "edit")
          setTimeout(res => {
            if (this.vehicleAssFleets)
              for (let g of this.vehicleAssFleets[this.selectedRow["vin"].split(",")].split(",")) {
                this.supervisorComobox.checkItem(g)
              }
          }, 100)
      })
  }

  async openShift() {
    let modal = await this.modalController.create({
      component: ShiftDetailsModelComponent,
      cssClass: this.directory != 'rtl' ? "shiftDetails" : "shiftDetails-rtl",
      componentProps: {
        mode: 'new'
      }
    })
    modal.onDidDismiss().then(() => {
      this.getShiftNames();
    });
    return await modal.present();
  }

  selectDropDown() {
    this.Debuggingmode.uncheckAll();
    this.employeesTypeComobox.selectItem(this.selectedRow["vehiclemodel_model"]);
    if (localStorage.companyRole == "CompanyAdmin") {
      if (this.selectedRow['assetInformation'] != "") {
        var debug = JSON.parse(this.selectedRow['assetInformation']);
        if (debug.debuggingMode == true)
          this.Debuggingmode.checkItem(true)
        else
          this.Debuggingmode.checkItem(false)
      } else
        this.Debuggingmode.checkItem(false)
    }
  }

  renderedItem(event, items, list) {
    console.log(event, items, list);
    this.commonService.renderedItem(event, items, list)
  }

  getShiftNames() {
    let url = serverUrl.web + "/employe/getShiftDetails"
    let data = {
      "companyID": localStorage.corpId,
      "branchID": localStorage.corpId,
      "userId": localStorage.userName
    }
    this.ajaxService.ajaxPostWithBody(url, data)
      .subscribe(res => {
        this.shiftNames = [{ label: "Select All", value: "All" }, ...res];
        console.log("this.shiftNames", this.shiftNames);

        let name = {};
        for (let n of this.shiftNames)
          name[n.shiftName] = n;
        if (this.mode == "edit")
          setTimeout(res => {
            for (let g of this.selectedRow["ShiftName"].split(","))
              if (name[g])
                this.shiftNameComobox.checkItem(g)
          }, 100)
      })
  }

  async openSimModel() {
    const model = await this.modalController.create({
      component: SimDeviceModalComponent,
      cssClass: this.directory != 'rtl' ? 'user_feature' : 'user_feature-rtl',
      componentProps: {
        page: "Device",
        mode: "Add"
      }
    })
    model.onDidDismiss().then(() => {
      this.getImei();
    })
    await model.present();
  }

  editSaveData(data) {
    this.editedDataArray = this.commonService.userAdminEditArray(this.mode, data, this.editedDataArray)
  }


  addEmployeeInToCompany() {
    var Obj = {};
    if (this.fleetLogin.value.serialNo && this.fleetLogin.value.employeeId && this.fleetLogin.value.employeeName && this.fleetLogin.value.employeeNameArabic
      && this.employeesTypeComobox.getSelectedItem() && this.fleetLogin.value.imei && this.fleetLogin.value.workingDaysPerMon
      && this.fleetLogin.value.avgSalaryPerHr) {
      Obj["plateNo"] = this.fleetLogin.value.employeeName + "@,@" + this.fleetLogin.value.employeeNameArabic;
      Obj["entryPointCheck"] = "WFT";
      Obj["companyID"] = localStorage.corpId;
      Obj["branchID"] = localStorage.corpId;
      Obj["username"] = localStorage.userName;
      Obj["userEntry"] = "Tabuq@gmail.com";
      Obj["vin"] = this.vin;
      Obj["tagID"] = this.fleetLogin.value.employeeId;
      Obj["noteTxt"] = this.fleetLogin.value.Note ? this.fleetLogin.value.Note : "---";
      Obj["serialNO"] = this.fleetLogin.value.serialNo.toString();
      Obj["imeiNo"] = this.fleetLogin.value.imei;
      Obj["dateofpurchase"] = this.fleetLogin.value.dateOfBirth ? this.fleetLogin.value.dateOfBirth : "";
      Obj["emailId"] = localStorage.password;
      Obj["contactNo"] = this.fleetLogin.value.contactNo ? (this.fleetLogin.value.contactNo).toString() : "true";
      Obj["icon"] = "Employee";
      Obj["type"] = "Employee"; //**** Don't change value its depend on tabuk WFT asset status ****//
      Obj["oldValue"] = JSON.stringify(this.commonService.userAdminControlEditDelete(this.editedDataArray, this.selectedRow, this.vehicleAssFleets));
      let employeeType = "";
      let Workzone: any = [];

      employeeType = this.employeesTypeComobox.getSelectedItem() ? this.employeesTypeComobox.getSelectedItem().value : "";
      if (employeeType)
        Obj["model"] = employeeType;
      else
        return this.commonService.presentToast("Please Select Employee Type")

      let fleetUser = "";
      for (let i = 0; i < this.supervisorComobox.getCheckedItems().length; i++) {
        fleetUser = i != 0 ? this.supervisorComobox.getCheckedItems()[i]["value"] + "," + fleetUser : this.supervisorComobox.getCheckedItems()[i]["value"];
      }
      let a: string;
      a = fleetUser.replace(",All", "")
      Obj["fleetUser"] = a + "," + localStorage.userName;
      if (!fleetUser)
        return this.commonService.presentToast("Please Select Supervisor")

      for (let i = 0; i < this.WorkzoneComobox.getCheckedItems().length; i++) {
        Workzone.push(this.WorkzoneComobox.getCheckedItems()[i]["value"])
      }
      (Workzone.includes("All")) ? Workzone.shift() : "";
      Obj["freeForm"] = Workzone;
      Obj["isFreeformArray"] = Workzone.length > 0 ? true : false;
      if (Workzone.length == 0)
        return this.commonService.presentToast("Please Select Workzone")

      let shiftName = "";
      for (let i = 0; i < this.shiftNameComobox.getCheckedItems().length; i++) {
        shiftName = i != 0 ? this.shiftNameComobox.getCheckedItems()[i]["originalItem"].shiftId + "," + shiftName : this.shiftNameComobox.getCheckedItems()[i]["originalItem"].shiftId + ",";
      }
      let b: string;
      b = shiftName.replace("undefined,", "")
      Obj["shift"] = b.toString();
      if (!shiftName)
        return this.commonService.presentToast("Please Select Shift")

      for (let i = 0; i < this.restDayComobox.getCheckedItems().length; i++) {
        Obj["restdayDT"] = Obj["restdayDT"] ? Obj["restdayDT"] : "";
        Obj["restdayDT"] += this.restDayComobox.getCheckedItems()[i]["value"] + ",";
      }
      if (this.restDayComobox.getCheckedItems().length == 0)
        return this.commonService.presentToast("Please Select restday")

      Obj["workingDaysPerMon"] = (this.fleetLogin.value.workingDaysPerMon).toString();
      Obj["cancon1"] = false,
        Obj["cancon2"] = false,
        Obj["cancon3"] = false
      Obj["avgSalaryPerHr"] = (this.fleetLogin.value.avgSalaryPerHr).toString();
      var debug = { debuggingMode: this.Debuggingmode.getSelectedItem() == null ? false : this.Debuggingmode.getSelectedItem().originalItem };
      Obj["debuggingMode"] = "{\"debuggingMode\":" + debug.debuggingMode + "}";

      if (this.mode == "edit") {
        if (Obj["shift"] != this.selectedRow["ShiftIds"]) {
          let oldshift = this.selectedRow["ShiftIds"].split(",")
          let newShift = Obj["shift"].split(",");
          for (let s = 0; s < oldshift.length; s++) {
            if (oldshift[s] && !newShift.includes(oldshift[s])) {
              if (s == 0)
                Obj["oldshift"] = "";
              Obj["oldshift"] = Obj["oldshift"] ? Obj["oldshift"] : "";
              Obj["oldshift"] += oldshift[s] + ",";
            }
          }
          Obj["oldshift"] = Obj["oldshift"] ? Obj["oldshift"] : "";
          //Obj["oldshift"] = this.selectedRow["ShiftIds"]
          Obj["isOldShift"] = Obj["oldshift"] ? true : false;
          Obj["checkshift"] = 1;
        }
        if (Obj["shift"] == this.selectedRow["ShiftIds"]) {
          // Obj["shift"] = "true";
          Obj["isOldShift"] = false;
          Obj["oldshift"] = "";
          Obj["checkshift"] = -1;
        }
        if (Obj["freeForm"] != this.selectedRow["WorkZone"]) {
          let oldfreeform = this.selectedRow["WorkZone"].split(",")
          let newfreeform = Obj["freeForm"];
          Obj["oldfreeForm"] = [];
          for (let s = 0; s < oldfreeform.length; s++) {
            if (oldfreeform[s] && !newfreeform.includes(oldfreeform[s])) {
              Obj["oldfreeForm"].push(oldfreeform[s]);
            }
          }
          Obj["oldfreeForm"] = Obj["oldfreeForm"] ? Obj["oldfreeForm"] : "";
          //   Obj["oldfreeForm"] = this.selectedRow["WorkZone"]
          Obj["isOldFreeFormArray"] = Obj["oldfreeForm"] ? true : false;

          Obj["isFreeformArray"] = Obj["freeForm"] == this.selectedRow["WorkZone"] ? false : true;

          Obj["checkFreeForm"] = 1;
        }
        if (Obj["freeForm"] == this.selectedRow["WorkZone"]) {
          Obj["freeForm"] = "true";
          Obj["isOldFreeFormArray"] = false;
          Obj["checkFreeForm"] = -1;
          Obj["isFreeformArray"] = false;
        }
        let deg = { debuggingMode: Boolean };
        if (localStorage.companyRole == "CompanyAdmin") {
          console.log(this.Debuggingmode.getCheckedItems())
          deg.debuggingMode = this.Debuggingmode.getCheckedItems() == null ? false : this.Debuggingmode.getCheckedItems()[0].originalItem;
        }
        else
          deg = undefined;
        Obj["vin"] = this.selectedRow.vin
        Obj["debuggingMode"] = "{\"debuggingMode\":" + deg.debuggingMode + "}";
      }

      console.log(Obj)

      var url = serverUrl.web
      let services = "";
      if (this.mode == "add")
        services = "/VehicleController/addvehdetails";
      else if (this.mode == "edit")
        services = "/VehicleController/editvehdetails";
      this.commonService.presentLoader();

      url = url + services;
      this.ajaxService.ajaxPostWithString(url, Obj)
        .subscribe(res => {
          let result = JSON.parse(res);
          if (Object.values(result).toString() == "persisted") {
            if (this.mode != "edit") {
              this.commonService.presentToast("Added successfully")
              let lowBatteryAlertData = { "mode": "new", "addalertJson": "{\"companyId\":\"empdemo\",\"branchId\":\"empdemo\",\"userId\":\"empdemo-ca\",\"CompanyAdmin\":\"CompanyAdmin\",\"push\":\"1\",\"time\":\"0:00_23:59\",\"vehicleDatas\":[{\"vin\":\"" + this.vin + "\",\"plateNo\":\"" + this.fleetLogin.value.employeeName + "@,@" + this.fleetLogin.value.employeeNameArabic + "\"}],\"days\":\"Sun#Mon#Tue#Wed#Thu#Fri#Sat\",\"alerttype\":\"LowBattery\",\"validity\":\"2\",\"status\":\"1\",\"throughSMS\":\"0\",\"throughSMS1\":\"0\",\"throughEmail\":\"0\",\"throughEmail1\":\"0\",\"interval\":\"10#Minutes\"}" }
              const url = serverUrl.web + '/Alert/add';
              this.ajaxService.ajaxPostWithString(url, lowBatteryAlertData)
                .subscribe(res => {
                  console.log("insert LowBattery res", res);

                })
            }
            else
              this.commonService.presentToast("Updated successfully")
            this.modalController.dismiss()
            this.getDatas();
            let data = this.vin
            if (this.mode == "edit")
              data = this.selectedRow.vin;
            this.ajaxService.ajaxPostWithBody(serverUrl.Admin + "/api/alerts/geoconfig", { vins: data.toString() })
              .subscribe(res => {

              })
            this.configalerts([{ vin: data.toString() }]);


          } else {
            this.commonService.presentToast("Failed")
          }
          this.commonService.dismissLoader();
        })
    } else
      this.commonService.presentToast("Please Enter Mandatory Fields")
  }

  configalerts(vindata) {
    let method = "alertconfigCacheByMultiVins";
    var json = {};
    json["method"] = method;
    json["companyId"] = localStorage.corpId;
    json["multivin"] = true;
    json["userId"] = localStorage.userName
    if (vindata.length > 1) {
      let vin = ''
      for (let i = 0; i < vindata.length; i++) {
        if (vindata.length - 1 == i)
          vin += "\'" + vindata[i].vin + "\'"
        else
          vin += "\'" + vindata[i].vin + "\',"
      }
      json["vin"] = vin;
    } else {
      json["vin"] = "\'" + vindata[0].vin + "\'";
    }
    const url = serverUrl.Admin + '/api/alerts/config';
    this.ajaxService.ajaxPostWithString(url, json)
      .subscribe(res => {
        // console.log("res",res);

      }, err => {
        this.commonService.presentToast("failed")
      });
  }



  formInitializer() {
    if (this.mode == "add") {
      this.fleetLogin = this.formBuilder.group({
        serialNo: ['', Validators.required],
        employeeId: ['', Validators.required],
        employeeName: ['', Validators.required],
        employeeNameArabic: ['', Validators.required],
        employeeType: ['', Validators.required],
        shiftName: ['', Validators.required],
        imei: ['', Validators.required],
        restDay: ['', Validators.required],
        supervisor: ['', Validators.required],
        Workzone: [''],
        Note: [''],
        dateOfBirth: [''],
        workingDaysPerMon: [''],
        avgSalaryPerHr: [''],
        contactNo: [''],
        Debugmode: ""
      });
    } else if (this.mode == "edit") {
      console.log(this.selectedRow)
      this.fleetLogin = this.formBuilder.group({
        serialNo: [this.selectedRow["Serial NO"], Validators.required],
        employeeId: [this.selectedRow["EmployeeID"], Validators.required],
        employeeName: [this.selectedRow["EmployeeName"], Validators.required],
        employeeNameArabic: [this.selectedRow["EmployeeNameArabic"], Validators.required],
        employeeType: [this.selectedRow["vehiclemodel_model"], Validators.required], // vehiclemodal
        shiftName: [this.selectedRow["ShiftName"], Validators.required],
        imei: [this.selectedRow["DeviceIMEINo"], Validators.required],
        restDay: [this.selectedRow["RestDay"], Validators.required],//////////////////
        supervisor: ['', Validators.required],////////////////
        Workzone: [this.selectedRow["WorkZone"]],///////////////////
        Note: [this.selectedRow.Note],
        dateOfBirth: [this.selectedRow.dateOfPurchase],
        workingDaysPerMon: [this.selectedRow.workingDaysPerMon],
        avgSalaryPerHr: [this.selectedRow.avgSalaryPerHr],
        contactNo: [this.selectedRow.contactNo],
        Debugmode: ""
      });
      setTimeout(res => {
        for (let r of this.selectedRow["RestDay"].split(","))
          this.restDayComobox.checkItem(r)
      }, 100)
    }
  }

  closeModal() {
    this.modalController.dismiss()
  }

  getDatas() {
    let url = serverUrl.web + "/VehicleController/getVehicleDetails"
    let data = {
      "companyId": localStorage.corpId,
      "branchId": localStorage.corpId,
      "userId": localStorage.userName,
      "dcName": "vehicleDisplayForEmpShift"
    }
    this.ajaxService.ajaxPostWithBody(url, data)
      .subscribe(res => {
        this.source = { localdata: res };
        this.dataAdapter = new jqx.dataAdapter(this.source);
        this.columns = [
          { text: this.trans.instant('Employee ID'), datafield: 'EmployeeID', cellsrenderer: this.renderer },
          { text: this.trans.instant('Employee Name'), datafield: "EmployeeName", cellsrenderer: this.renderer },
          { text: this.trans.instant('Employee Name In Arabic'), datafield: "EmployeeName", cellsrenderer: this.renderer },
          { text: this.trans.instant('Device IMEINo'), datafield: 'DeviceIMEINo', cellsrenderer: this.renderer },
          { text: this.trans.instant('Shift Name'), datafield: 'ShiftName', cellsrenderer: this.renderer },
          { text: this.trans.instant('Work Zone'), datafield: 'WorkZone', cellsrenderer: this.renderer },
          { text: this.trans.instant('Rest Day'), datafield: 'RestDay', cellsrenderer: this.renderer },
          { text: this.trans.instant('Note'), datafield: 'Note', cellsrenderer: this.renderer },
        ]
        this.commonService.dismissLoader();
      })
  }


  debugValue(event) {
    if (event.args.item.value == "true") {
      this.Debuggingmode.uncheckItem("false")
    } else {
      this.Debuggingmode.uncheckItem("true")
    }
  }

  ngOnInit() {

    this.getImei();
    this.formInitializer();
    this.getVehicleAssociatedFleetManager();
    this.getFleetManager();
    this.getShiftNames();
    this.getEmployeeType();
    this.generateVin();
    this.getGeofenceData();
    if (localStorage.companyRole == "CompanyAdmin")
      this.debugmode = true

    setTimeout(res => {
      this.Debuggingmode.checkItem("false");
      if (this.mode == "edit")
        this.selectDropDown()
    }, 1000)
  }
}
