import { Component, Input, OnChanges, OnInit, ViewChild } from '@angular/core';
import { jqxComboBoxComponent } from 'jqwidgets-ng/jqxcombobox';
import { ApiService } from 'src/app/services/api.service';
import { CommonService } from 'src/app/services/common.service';
import { storageVariable } from 'src/environments/environment';

@Component({
  selector: 'app-video',
  templateUrl: './video.component.html',
  styleUrls: ['./video.component.scss'],
})
export class VideoComponent implements OnInit, OnChanges {
  @Input() from;
  @Input() for;
  @Input() selectedPage;
  @Input() selectedVin;
  // selectedPage: string = 'live';
  @ViewChild("plateNoComobox", { static: false }) plateNoComobox: jqxComboBoxComponent;
  dashboardJson = [];
  constructor(private apiService: ApiService,
    private cs: CommonService

  ) { }
  plateNoList: any[];
  plateNos: any;
  ngOnChanges() {
    // console.log("selectedPage", this.selectedPage);
    console.log("selectedVin", this.selectedVin.plateNo);

    if (this.from == 'grid') {
      this.for == "live" ? this.apiService.first(this.cs.tokObj) : this.apiService.second(this.cs.tokObj)
    } else {
      (this.selectedPage == "live") ? this.apiService.first(this.cs.tokObj) : this.apiService.second(this.cs.tokObj)
    }

  }
  comboFunc() {
    console.log("calling");

    this.plateNoList = Object.values(this.dashboardJson);
    this.plateNoList.map((res, index) => {
      this.plateNoList[index]['arabicPlateNo'] = this.plateNoList[index].plateNo.split("@,@").length >= 2 ? this.plateNoList[index].plateNo.split("@,@")[0] + ` (${this.plateNoList[index].plateNo.split("@,@")[1]})` : this.plateNoList[index].plateNo.split("@,@")[0]
    })
    var source = {
      datatype: 'json',
      datafields: [{ name: 'plateNo' }, { name: 'arabicPlateNo' }, { name: 'vin' }],
      id: 'id',
      localdata: this.plateNoList
    }
    setTimeout(() => {
      this.plateNoComobox.selectItem(this.plateNoList[0].vin)
    }, 500)

    this.plateNos = new jqx.dataAdapter(source);
    console.log("this.plateNos", this.plateNos);


  }
  changePlateNo() { }


  ngOnInit(): void {
    console.log("from", this.from);
    console.log("for", this.for);

    if (this.from == 'grid') {
      this.for == "live" ? this.apiService.first(this.cs.tokObj) : this.apiService.second(this.cs.tokObj)
    } else {
      (this.selectedPage == "live") ? this.apiService.first(this.cs.tokObj) : this.apiService.second(this.cs.tokObj)
    }
    let a: any[] = Object.values(storageVariable.dashboardData.liveDatas);
    for (let i = 0; i < a.length; i++) {
      if (!a[i]['warrantyExpiry'])
        this.dashboardJson.push(a[i]);
    }



  }

}
