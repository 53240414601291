import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'dashcamsearch',
})
export class DashCamSearchPipe implements PipeTransform {
    transform(items: any[], terms: string): any[] {
        if (!items) return [];
        if (!terms) return items;
        terms = terms.trim().toLowerCase();
        const exactMatches = [];
        const partialMatches = []
        items.forEach(it => {
            if (it.plateNo && it.plateNo.trim()) {
                const normalizedPlateNo = it.plateNo.replace(/^\s+|\s+$/g, '').toLowerCase();
                if (normalizedPlateNo === terms) {
                    exactMatches.push(it);
                }
                if (normalizedPlateNo.includes(terms)) {
                    partialMatches.push(it);
                }
            }
        });
        const combined = [...exactMatches, ...partialMatches];
        return combined.filter((item, index, self) =>
            index === self.findIndex(t => t.plateNo === item.plateNo)
        );
    }


}
