import { Injectable } from '@angular/core';
@Injectable({
  providedIn: 'root',
})
export class DateTimeService {
  getDateInTimeZone(date: Date, timeZone: string): Date {
    const formatter = new Intl.DateTimeFormat('en-US', {
      timeZone,
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: false,
    });

    const parts = formatter.formatToParts(date);
    const yearPart = parts.find((p) => p.type === 'year');
    const year = parseInt((yearPart && yearPart.value) || '0', 10);

    const monthPart = parts.find((p) => p.type === 'month');
    const month = parseInt((monthPart && monthPart.value) || '0', 10) - 1;

    const dayPart = parts.find((p) => p.type === 'day');
    const day = parseInt((dayPart && dayPart.value) || '0', 10);

    const hourPart = parts.find((p) => p.type === 'hour');
    const hour = parseInt((hourPart && hourPart.value) || '0', 10);

    const minutePart = parts.find((p) => p.type === 'minute');
    const minute = parseInt((minutePart && minutePart.value) || '0', 10);

    const secondPart = parts.find((p) => p.type === 'second');
    const second = parseInt((secondPart && secondPart.value) || '0', 10);

    return new Date(year, month, day, hour, minute, second);
  }

  formatDateToTimestamp(date: Date): string {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hour = String(date.getHours()).padStart(2, '0');
    const minute = String(date.getMinutes()).padStart(2, '0');
    const second = String(date.getSeconds()).padStart(2, '0');

    return `${year}-${month}-${day} ${hour}:${minute}:${second}`;
  }
  getCurrentTimestampForLondon(): string {
    const currentDate = new Date();
    const londonDate = this.getDateInTimeZone(currentDate, 'Europe/London');
    return this.formatDateToTimestamp(londonDate);
  }
}
