import { Component, OnInit, ViewChild, OnChanges } from '@angular/core';
import { jqxGridComponent } from 'jqwidgets-ng/jqxgrid';
import 'jspdf-autotable';
import { jsPDF } from 'jspdf';
import { ExportExcelService } from '../../services/export-excel.service';
import { ModalController } from '@ionic/angular';
import { FleetFormComponent } from '../fleet-form/fleet-form.component';
import { AjaxService } from 'src/app/services/ajax.service';
import { CommonService } from 'src/app/services/common.service';
import { app, languageInitializer, serverUrl, storageVariable } from '../../../environments/environment';
import { Platform, AlertController } from '@ionic/angular';
import { Plugins, FilesystemDirectory } from '@capacitor/core';
import { FileOpener } from '@ionic-native/file-opener/ngx';
import { AdvManageVehicleComponent } from 'src/app/manage-fleet/adv-manage-vehicle/adv-manage-vehicle.component';
import { TranslateService } from '@ngx-translate/core';
import { WebsocketService } from 'src/app/services/websocket.service';

const { Filesystem, Storage } = Plugins;

export const File_Key = 'files';

@Component({
  selector: 'app-manage-fleet-table',
  templateUrl: './manage-fleet-table.component.html',
  styleUrls: ['./manage-fleet-table.component.scss'],
})
export class ManageFleetTableComponent implements OnInit {
  @ViewChild(FleetFormComponent, { static: false }) fleetFormComponent: FleetFormComponent;
  titles = 'jspdf-autotable-demo';
  title = 'angular-export-to-excel'
  dataForExcel = [];
  directory = languageInitializer.directory;
  @ViewChild('myGrid', { static: false }) myGrid: jqxGridComponent;
  objValues = [];
  odj = [];
  columns: ({ text: string; datafield: string; cellsrenderer: (row: number, column: any, value: string) => string; } | { text: string; datafield: string; cellsrenderer?: undefined; })[];
  selectedRow: any;
  editrow: number;
  tableData: any;
  getAdapter: any;
  datas = [];
  selectedRowIdx: any;
  pdfdatas = [];
  isDeleteShow: any = false;
  count: any = 15;
  localizationobj: {};
  groupsAndVin: any;
  pagesizeoption = ["5", "10", "13", "15", "20"];
  delSelectedRow: any = [];
  submitbtn: boolean = false;


  constructor(
    private modalController: ModalController,
    private fileOpener: FileOpener,
    private ete: ExportExcelService,
    private ajaxService: AjaxService,
    private commonService: CommonService,
    public platform: Platform,
    private alertController: AlertController,
    private trans: TranslateService,
    private websocketService: WebsocketService
  ) { }

  source: { localdata: any; };
  dataAdapter: any;
  renderer: (row: number, column: any, value: string) => string;

  head = ['Plate No', 'Imei Number', "Group", 'Icon', 'Odometer', 'Model', 'Make', "Operator", "Status"];
  exportTitle = [];
  excelTitle = {};
  newTitle = [];
  column = [];
  details: any;
  detail: any;
  newDetail: any;
  //;

  exportToExcel() {
    // For filtered rows
    //let plateNumber=this.detail["Plate Number"];
    const filteredRows = this.myGrid.getrows();
    const filteredData = filteredRows.map(row => {
      const obj = {};
      obj["Plate No"] = row["Plate Number"];
      obj["Imei Number"] = row["IMEI Number"];
      obj["Group"] = row.Group;
      obj["Icon"] = row.iconUrl;
      obj["Odometer"] = row.totalOdometer;
      obj["Model"] = row.Make;
      obj["Make"] = row.Type;
      obj["Operator"] = row["Operator Name"];
      obj["Status"] = row.warrantyExpiry;
      return obj;
    });
    //For filtered rows
    let reportData = {
      title: 'Manage Fleets',
      data: this.pdfdatas,
      headers: this.head,
      data1: filteredData
    }
    this.ete.exportExcel(reportData);
    //console.log("Export Excel")
  }

  async createPdf() {
    // For filtered rows
    const filteredRows = this.myGrid.getrows();
    const filteredData = filteredRows.map(row => {
      const obj = {};
      obj["Plate No"] = row["Plate Number"];
      obj["Imei Number"] = row["IMEI Number"];
      obj["Group"] = row.Group;
      obj["Icon"] = row.iconUrl;
      obj["Odometer"] = row.totalOdometer;
      obj["Model"] = row.Make;
      obj["Make"] = row.Type;
      obj["Operator"] = row["Operator Name"];
      obj["Status"] = row.warrantyExpiry;
      return obj;
    });
    //For filtered rows
    this.commonService.createPdf(this.head, this.pdfdatas, filteredData, "Manage Fleets", "", "Manage Fleets", null)
  }

  private getMimetype(name) {
    if (name.indexOf('pdf') >= 0) {
      return 'application/pdf'
    } else if (name.indexOf('png') >= 0) {
      return 'image/png'
    } else if (name.indexOf('mp4') >= 0) {
      return 'video/png'
    }
  }

  deletebtn() {
    let selectedrowindex = this.myGrid.getselectedrowindex();
    let rowscount = this.myGrid.getdatainformation().rowscount;
    if (selectedrowindex >= 0 && selectedrowindex < parseFloat(rowscount)) {
      let id = this.myGrid.getrowid(selectedrowindex);
      this.myGrid.deleterow(id);
      //console.log(id)
    }
  }
  configalerts(vindata) {
    let method = "alertconfigCacheByMultiVins";
    var json = {};
    json["method"] = method;
    json["companyId"] = localStorage.corpId;
    json["multivin"] = true;
    json["userId"] = localStorage.userName
    if (vindata.length > 1) {
      let vin = ''
      for (let i = 0; i < vindata.length; i++) {
        if (vindata.length - 1 == i)
          vin += "\'" + vindata[i].vin + "\'"
        else
          vin += "\'" + vindata[i].vin + "\',"
      }
      json["vin"] = vin;
    } else {
      json["vin"] = "\'" + vindata[0].vin + "\'";
      // json["vin"] =  vindata[0].vin ;
      console.log(json["vin"], "vin");

    }
    const url = serverUrl.Admin + '/api/alerts/config';
    this.ajaxService.ajaxPostWithString(url, json)
      .subscribe(res => {
        console.log("res", res);

      }, err => {
        this.commonService.presentToast("failed")
      });
  }
  async deleteMode() {

    if (this.selectedRow) {
      const alert = await this.alertController.create({
        header: this.trans.instant('Are you sure?'),
        backdropDismiss: false,
        message: this.trans.instant("You want to delete!"),
        buttons: [{
          text: this.trans.instant('Cancel'),
          role: 'cancel',
          handler: data => {

          }
        },
        {
          text: this.trans.instant('Ok'),
          handler: data => {
            let allRows = this.myGrid.attrSource["originaldata"];

            let selectedRowIndex: any = this.myGrid.getselectedrowindexes();
            console.log(this.myGrid);




            let selectArrayRow = []
            if (selectedRowIndex.length > 0) {
              for (let i = 0; i < selectedRowIndex.length; i++) {
                this.delSelectedRow.push(allRows[selectedRowIndex[i]]["Vehicle Number"])
                console.log("this.delSelectedRow", allRows[selectedRowIndex[i]]["Vehicle Number"]);

                selectArrayRow.push({
                  "companyID": localStorage.getItem('corpId'),
                  "branchID": localStorage.getItem('corpId'),
                  "emailId": localStorage.getItem('userName'),
                  "vin": allRows[selectedRowIndex[i]]["Vehicle Number"],
                  "plateNo": allRows[selectedRowIndex[i]]["Plate Number"],
                  "imeiNo": allRows[selectedRowIndex[i]]["IMEI Number"],
                  "operatorId": allRows[selectedRowIndex[i]]["OperatorId"],
                  "effFrom": allRows[selectedRowIndex[i]]["effFrom"],
                  "macIp": ""
                })
              }

              const url = serverUrl.web + '/VehicleController/delvehdetails';
              this.ajaxService.ajaxPostMethod(url, selectArrayRow).subscribe(res => {
                //console.log(res);
                if (res.jsonfinalstatus == "persisted") {
                  if (this.websocketService.isAlive("livetrack")) {
                    this.websocketService.reSendRequest(JSON.parse(localStorage.dashboardWebSocketData));
                  }
                  this.ajaxService.groupService();
                  this.commonService.presentToast("Deleted successfully");
                  let selectRow: any = this.myGrid.getselectedrowindexes();

                  console.log("selectedRowIndex", this.delSelectedRow);
                  let aaa = [{ vin: this.delSelectedRow }]
                  this.configalerts(aaa);
                  this.delSelectedRow = [];
                  this.myGrid.clearselection();
                  this.getDatas();
                } else {
                  this.commonService.presentToast("Error in deleting")
                }

              })
            } else {
              this.commonService.presentToast('Please select a row to Delete');
            }
          }
        }]
      });
      await alert.present();

    }
    else {
      this.commonService.presentToast('Please select a row to Delete');
      return "";

    }

  }

  myGridOnRowSelect(event: any): void {
    this.selectedRow = event.args.row;
    console.log("this.selectedRow", this.selectedRow);

    this.selectedRowIdx = event.args.rowindex;

  }
  async openModel() {
    if (localStorage.getItem("entryPoint") != "ATM") {
      var modal = await this.modalController.create({
        component: AdvManageVehicleComponent,
        cssClass: this.directory != 'rtl' ? 'advManageFleet' : "advManageFleet-rtl",
        componentProps: {
          mode: "add",
          groupsAndVin: this.groupsAndVin
        }
      });
    } else {
      var modal = await this.modalController.create({
        component: AdvManageVehicleComponent,
        cssClass: this.directory != 'rtl' ? 'advManageFleetatm' : "advManageFleetatm-rtl",
        componentProps: {
          mode: "add",
          groupsAndVin: this.groupsAndVin
        }
      });
    }
    modal.onDidDismiss().then(() => {
      this.ajaxService.groupService()
      if (this.myGrid)
        this.myGrid.clearselection();
      if (this.commonService.isLoading)
        this.commonService.dismissLoader()
      this.selectedRow = "";
      this.getDatas();
    })
    return await modal.present();
  }
  groupService() {
    const url = serverUrl.web + "/site/getVinGroup";
    let body = {
      companyId: localStorage.corpId,
      branchId: localStorage.corpId,
      userId: localStorage.userName
    }
    this.ajaxService.ajaxPostMethod(url, body)
      .subscribe(res => {
        let a = res

        storageVariable.groupAndVin = res;
        this.groupsAndVin = res;
      })

  }


  async editMode(selectedCard) {
    let selectedRowIndex: any = this.myGrid.getselectedrowindexes()


    if (this.selectedRow) {
      if (selectedRowIndex.length != 1) {
        this.commonService.presentToast("Select one row for edit")
        this.myGrid.clearselection();
        return 0;
      }
      this.selectedRow["submit"] = "available";
      if (localStorage.getItem("entryPoint") != "ATM") {
        var modal = await this.modalController.create({
          component: AdvManageVehicleComponent,
          cssClass: this.directory != 'rtl' ? 'advManageFleet' : "advManageFleet-rtl",
          componentProps: {
            mode: "edit",
            selectedRow: this.selectedRow
          }
        });
      } else {
        var modal = await this.modalController.create({
          component: AdvManageVehicleComponent,
          cssClass: this.directory != 'rtl' ? 'advManageFleetatm' : "advManageFleetatm-rtl",
          componentProps: {
            mode: "edit",
            selectedRow: this.selectedRow
          }
        });
      }
      modal.onDidDismiss().then(() => {
        this.myGrid.clearselection();
        if (this.commonService.isLoading)
          this.commonService.dismissLoader()
        this.selectedRow = "";
        this.getDatas();
        this.ajaxService.groupService();
      })

      return await modal.present();

    }
    else {
      this.commonService.presentToast('Please select a row to edit');
      return "";

    }

  }

  async multiEditMode() {
    if (localStorage.getItem("entryPoint") != "ATM") {
      var modal = await this.modalController.create({
        component: AdvManageVehicleComponent,
        cssClass: this.directory != 'rtl' ? 'advManageFleet' : "advManageFleet-rtl",
        componentProps: {
          mode: "multiEdit",
          overallData: this.tableData
        }
      });
    } else {
      var modal = await this.modalController.create({
        component: AdvManageVehicleComponent,
        cssClass: this.directory != 'rtl' ? 'advManageFleetatm' : "advManageFleetatm-rtl",
        componentProps: {
          mode: "multiEdit",
          overallData: this.tableData
        }
      });
    }
    modal.onDidDismiss().then(() => {
      this.myGrid.clearselection();
      if (this.commonService.isLoading)
        this.commonService.dismissLoader()
      this.selectedRow = "";
      this.getDatas();
      this.ajaxService.groupService()
    })
    return await modal.present();
  }
  loadMoreData = (event) => {
    setTimeout(() => {
      console.log("hit successfull!");
      this.count += 10;
      event.target.complete();

      if (this.count > this.detail.length) {
        event.target.disabled = true;
      }
    }, 500);
  }
  ngAfterViewInit() {
    this.myGrid.showloadelement();

    this.getDatas();
  }
  ionViewWillEnter() {
    this.myGrid.showloadelement();

    this.getDatas();
  }
  ngOnInit() {
    if (localStorage.manageADM == "true" && localStorage.companyRole == "FleetManager") {
      this.submitbtn = true;
    }
    //this.ajaxService.groupService()
    let localMainMenu = JSON.parse(localStorage.mainMenu)
    this.isDeleteShow = localMainMenu.includes("Delete")
    this.tableData = JSON.parse(localStorage.getItem('gridDataa'))
    this.renderer = (row: number, column: any, value: string,) => {
      if (value == "" || null || undefined) {
        return this.trans.instant("----No Data----")
      }
      else {
        return '<span  style="line-height:32px;font-size:11px;color:darkblue;margin:auto;padding:0px 5px">' + value + '</span>';
      }
    }
    this.source = { localdata: this.tableData };
    this.dataAdapter = new jqx.dataAdapter(this.source);
    this.columns = [
      { text: this.trans.instant('Plate No'), datafield: 'plateNo', cellsrenderer: this.renderer },
      // {text :'Operator',datafield:'operatorName',cellsrenderer:this.renderer},
      { text: this.trans.instant('Imei Number'), datafield: 'imeiNo', cellsrenderer: this.renderer },
      // {text :'Serial No',datafield:'serialNo',cellsrenderer:this.renderer},
      { text: this.trans.instant('Icon'), datafield: 'iconUrl', cellsrenderer: this.renderer },
      { text: this.trans.instant('Odometer'), datafield: 'totalOdometer', cellsrenderer: this.renderer },
      { text: this.trans.instant('Model'), datafield: 'make', cellsrenderer: this.renderer },
      { text: this.trans.instant('Make'), datafield: 'Type', cellsrenderer: this.renderer },
    ]
    this.localizationobj = {
      pagergotopagestring: this.trans.instant("Go to page"),
      pagershowrowsstring: this.trans.instant("Show rows"),
      sortascendingstring: this.trans.instant("Sort Ascending"),
      sortdescendingstring: this.trans.instant("Sort Descending"),
      sortremovestring: this.trans.instant("Remove Sort")
    }
    this.commonService.updateLanguageDir
      .subscribe(res => {
        this.directory = res == "" ? this.directory : res
        this.tableFormation()
      })


  }


  getDatas() {

    const companyDetail = {
      branchID: localStorage.getItem('corpId'),
      companyID: localStorage.getItem('corpId'),
      userId: localStorage.getItem('userName')
    }


    let url = serverUrl.web + '/VehicleController/getVehicleDetails';
    const data = {
      branchId: localStorage.corpId,
      companyId: localStorage.corpId,
      dcName: "vehicleDisplay",
      userId: localStorage.userName
    }
    if (localStorage.corpId)
      this.ajaxService.ajaxPostMethod(url, data).subscribe(res => {
        var detail = res;
        this.tableData = res
        this.pdfdatas = [];
        for (var i = 0; i < detail.length; i++) {
          this.pdfdatas.push([detail[i]["Plate Number"], detail[i]["IMEI Number"], detail[i].Group, detail[i].iconUrl, detail[i].totalOdometer, detail[i].Make, detail[i].Type, detail[i]["Operator Name"], detail[i]['warrantyExpiry'] == "true" ? (detail[i]['warrantyExpiry'] = 'Suspend') : (detail[i]['warrantyExpiry'] = "Active")]);
        }
        this.renderer = (row: number, column: any, value: string,) => {
          if (value == "" || null || undefined) {
            return this.trans.instant("----No Data----")
          }
          else {
            return '<span  style="line-height:32px;font-size:11px;color:darkblue;margin:auto;padding:0px 5px">' + value + '</span>';
          }
        }
        this.source = { localdata: this.tableData };
        this.dataAdapter = new jqx.dataAdapter(this.source);
        this.tableFormation();
      })



  }
  tableFormation() {
    let entryPoint = localStorage.getItem("entryPoint")
    if (entryPoint == "ATM") {
      this.columns = [
        { text: this.trans.instant('ATM ID'), datafield: 'Plate Number', cellsrenderer: this.renderer },
        { text: this.trans.instant('Imei Number'), datafield: 'IMEI Number', cellsrenderer: this.renderer },
        { text: this.trans.instant('Group'), datafield: 'Group', cellsrenderer: this.renderer },
        { text: this.trans.instant('Icon'), datafield: 'iconUrl', cellsrenderer: this.renderer },
        //{ text: this.trans.instant('Odometer'), datafield: 'Odometer', cellsrenderer: this.renderer },
        { text: this.trans.instant('Model'), datafield: 'Make', cellsrenderer: this.renderer },
        // { text: this.trans.instant('Make'), datafield: 'Type', cellsrenderer: this.renderer },
        // { text: this.trans.instant('Operator'), datafield: 'Operator Name', cellsrenderer: this.renderer },
        { text: this.trans.instant('Status'), datafield: 'warrantyExpiry', cellsrenderer: this.renderer },
        //{ text: this.trans.instant('Debugging Mode'), datafield: 'debuggingMode', cellsrenderer: this.renderer }
      ]
    } else {
      this.columns = [
        { text: this.trans.instant('Plate No'), datafield: 'Plate Number', cellsrenderer: this.renderer },
        { text: this.trans.instant('Imei Number'), datafield: 'IMEI Number', cellsrenderer: this.renderer },
        { text: this.trans.instant('Group'), datafield: 'Group', cellsrenderer: this.renderer },
        { text: this.trans.instant('Icon'), datafield: 'iconUrl', cellsrenderer: this.renderer },
        { text: this.trans.instant('Odometer'), datafield: 'totalOdometer', cellsrenderer: this.renderer },
        { text: this.trans.instant('Model'), datafield: 'Make', cellsrenderer: this.renderer },
        { text: this.trans.instant('Make'), datafield: 'Type', cellsrenderer: this.renderer },
        { text: this.trans.instant('Operator'), datafield: 'Operator Name', cellsrenderer: this.renderer },
        { text: this.trans.instant('Status'), datafield: 'warrantyExpiry', cellsrenderer: this.renderer }
      ]
    }
    if (this.myGrid) {
      this.myGrid.pagesizeoptions(this.pagesizeoption)
      this.myGrid.updatebounddata();
      this.myGrid.unselectrow;
    }
  }

}
