import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-candata',
  templateUrl: './candata.component.html',
  styleUrls: ['./candata.component.scss'],
})
export class CandataComponent implements OnInit, OnChanges {
  data = {};
  @Input() updating;
  constructor() { }

  ngOnChanges(changes: SimpleChanges): void {
    // console.log("this.updating", this.updating.plateNo, this.updating.timeStamp, this.updating);
    this.data = this.updating;
  }
  ngOnInit() {
    // console.log("this.updating", this.updating.plateNo);
    this.data = this.updating;
  }

}
