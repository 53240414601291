import { Component, Input, OnInit } from '@angular/core';
import * as Highcharts from 'highcharts';

@Component({
  selector: 'app-battery-health',
  templateUrl: './battery-health.component.html',
  styleUrls: ['./battery-health.component.scss'],
})
export class BatteryHealthComponent {

  constructor() { }
  @Input() selectedvin;

  ngOnChanges(){
    this.gaugechart(this.selectedvin);
  }

    gaugechart(selectedVin) {
      setTimeout(() => {
        if (document.getElementById('batteryGaugeChart')) {
          const socValue = selectedVin && selectedVin.externalBatteryPercentage
            ? parseFloat(selectedVin.externalBatteryPercentage.replace('%', '')) || 0
            : 0;
          const remainingCapacityValue = 100 - socValue;
    
          console.log(remainingCapacityValue, "mmmmm", socValue);
    
          Highcharts.chart("batteryGaugeChart", {
            chart: {
              type: 'pie',
            },
            title: {
              text: '',
              style: { fontSize: '0px' }
            },
            plotOptions: {
              pie: {
                innerSize: '60%', 
                allowPointSelect: false,
                dataLabels: {
                  enabled: true,
                  formatter: function () {
                    return `<b>${this.point.name}</b>: ${this.y}%`;
                  },
                  style: { fontSize: '14px', color: '#000' }
                },
                showInLegend: false
              }
            },
            series: [{
              name: 'Battery Status',
              data: [
                { 
                  name: 'Used', 
                  y: remainingCapacityValue, 
                  color: '#FF0000'
                },
                { 
                  name: 'SOC', 
                  y: socValue, 
                  color: '#1eb15d' 
                }
              ]
            }],
            legend: {
              layout: 'horizontal',
              align: 'center',
              verticalAlign: 'bottom',
              itemStyle: {
                fontSize: '10px',
                color: '#1A237E'
              }
            },
            tooltip: {
              shared: true,
              pointFormat: '<b>{point.name}: {point.y}%</b>'
            },
            credits: { enabled: false }
          } as any);
        } else {
          console.error('Chart container not found!');
        }
      }, 1000); 
    }
    

}
