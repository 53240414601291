import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalController, AlertController, Platform } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { jqxGridComponent } from 'jqwidgets-ng/jqxgrid';
import { SimDeviceModalComponent } from 'src/app/components/sim-device-modal/sim-device-modal.component';
import { AjaxService } from 'src/app/services/ajax.service';
import { CommonService } from 'src/app/services/common.service';
import { app, languageInitializer, serverUrl } from 'src/environments/environment';
import { ExportExcelService } from '../../services/export-excel.service';

@Component({
  selector: 'app-vts-devices',
  templateUrl: './vts-devices.component.html',
  styleUrls: ['./vts-devices.component.scss'],
})
export class VtsDevicesComponent implements OnInit {
  source: { localdata: any; };
  tableData: any;
  renderer: (row: number, column: any, value: string) => string;
  dataAdapter: any;
  @ViewChild('myGrid', { static: false }) myGrid: jqxGridComponent;
  columns: { text: string; datafield: string; cellsrenderer: (row: number, column: any, value: string) => string; }[];
  selectedRow: any;
  directory: string = languageInitializer.directory;
  localizationobj: {};
  pdfdatas = [];
  submitbtn: boolean = false;

  constructor(
    private ajaxService: AjaxService,
    private modalController: ModalController,
    private commonService: CommonService,
    private alertController: AlertController,
    private trans: TranslateService,
    public platform: Platform,
    private ete: ExportExcelService,
  ) { }
  head = ['IMEI Number', 'Plate Number', 'Manufacturer', 'Model', 'Date Of Purchase', 'Warrenty experiy date', 'SIM Card Number', 'Status'];

  myGridOnRowSelect(event) {
    this.selectedRow = event.args.row
    console.log("this.selectedRow", this.selectedRow);

  }

  deleteSim() {
    const url = serverUrl.web + "/DCFramework/deleteSimCard"
    const data = {
      "compnyID": localStorage.corpId,
      "branchID": localStorage.corpId,
      "user": localStorage.userName,
      "simCardNo": this.selectedRow.simCardNo,
      "macIp": ""
    }
    this.ajaxService.ajaxDeleteWithBody(url, data)
      .subscribe(res => {
        this.commonService.presentToast("Sim card deleted successfully")
        this.myGrid.clearselection();
        this.selectedRow = "";
        this.renderGridTable();
      })
  }

  async openSimModel() {
    const model = await this.modalController.create({
      component: SimDeviceModalComponent,
      cssClass: this.directory != 'rtl' ? 'user_feature' : 'user_feature-rtl',
      componentProps: {
        page: "Device",
        mode: "Add"
      }
    })
    model.onDidDismiss().then(() => {
      this.myGrid.clearselection();
      this.selectedRow = "";
      this.renderGridTable();
    })
    await model.present();
  }

  async editSimModal() {
    if (!this.selectedRow)
      return this.commonService.presentToast("Please select row")
    const model = await this.modalController.create({
      component: SimDeviceModalComponent,
      cssClass: this.directory != 'rtl' ? 'user_feature' : 'user_feature-rtl',
      componentProps: {
        page: "Device",
        mode: 'edit',
        selectedRow: this.selectedRow
      }
    })
    model.onDidDismiss().then(() => {
      this.myGrid.clearselection();
      this.selectedRow = "";
      this.renderGridTable();
    })
    await model.present();
  }

  async deleteDevice() {
    if (this.selectedRow['Plate Number'] == undefined || this.selectedRow['Plate Number'] == "") {
      if (this.selectedRow) {
        const alert = await this.alertController.create({
          header: this.trans.instant('Are you sure?'),
          backdropDismiss: false,
          message: this.trans.instant("Do you want to delete your Record?. If you delete it, your changes will be lost."),
          buttons: [{
            text: this.trans.instant('Cancel'),
            role: 'cancel',
            handler: data => {
            }
          },
          {
            text: this.trans.instant('Ok'),
            handler: data => {

              const url = serverUrl.web + "/DCFramework/deleteDeviceDetails";
              const datas = {
                "IMEIno": this.selectedRow['IMEI Number'],
                "branchID": localStorage.corpId,
                "compnyID": localStorage.corpId,
                "simNo": this.selectedRow['SIM Card Number'],
                "user": localStorage.userName,
                "macIp": ""
              }
              this.ajaxService.ajaxPostWithString(url, datas)
                .subscribe(res => {

                  this.commonService.presentToast("Device deleted successfully")
                  this.myGrid.clearselection();
                  this.selectedRow = "";
                  this.renderGridTable();
                })
            }
          }]
        });
        await alert.present();
      }
      else {
        this.commonService.presentToast('Please Select a Row to Delete');
        return "";

      }
    } else {
      this.commonService.presentToast('Associated device cannot be deleted');
      return "";

    }

  }

  renderGridTable() {
    let url = serverUrl.web + "/DCFramework/getDeviceDetails?compId=" + localStorage.corpId + "&userId=" + localStorage.userName;
    this.ajaxService.ajaxGetJson(url)
      .subscribe(res => {
        this.tableData = res
        this.pdfdatas = [];
        for (var i = 0; i < this.tableData.length; i++) {
          this.pdfdatas.push([this.tableData[i]['IMEI Number'], this.tableData[i]['Plate Number'], this.tableData[i].Manufacturer, this.tableData[i].Model, this.tableData[i]['Date of Purchase'], this.tableData[i]['Warranty Expiry Date'], this.tableData[i]['SIM Card Number'], this.tableData[i]['warrantyExpiry'] === "true" ? (this.tableData[i]['warrantyExpiry'] = 'suspend') : (this.tableData[i]['warrantyExpiry'] = "active")]);
        }
        this.tableData.map(res => res["Plate Number"] ? res["Plate Number"] : res["Plate Number"] = "")
        this.renderer = (row: number, column: any, value: string,) => {
          if (column == "Plate Number" && value.split("@,@")[1])
            return '<span  style="line-height:32px;font-size:11px;color:darkblue;margin:auto;padding:0px 5px">' + value.split("@,@")[0] + ` (${value.split("@,@")[1]})</span>`;
          if (value == "" || null || undefined) {
            return "----No Data----"
          }
          else {
            return '<span  style="line-height:32px;font-size:11px;color:darkblue;margin:auto;padding:0px 5px">' + value + '</span>';
          }
        }
        this.source = { localdata: this.tableData };
        this.dataAdapter = new jqx.dataAdapter(this.source);
        if (this.commonService.dashcamLogin == true) {
          this.columns = [
            { text: this.trans.instant('Imei no'), datafield: 'IMEI Number', cellsrenderer: this.renderer },
            { text: this.trans.instant('Plate no'), datafield: 'Plate Number', cellsrenderer: this.renderer },
            {
              text: this.trans.instant('Device Type'), datafield: 'deviceType', cellsrenderer: (row: number, column: any, value: string): string => {
                if (!value) {
                  return '<div style="text-align: center; color: darkblue;font-size: 11px; width: 100%">MainDevice</div>';
                }
                return `<div style="text-align: center;font-size: 11px; width: 100%">${value}</div>`;
              }
            },
            { text: this.trans.instant('Manufacture name'), datafield: 'Manufacturer', cellsrenderer: this.renderer },
            { text: this.trans.instant('Model'), datafield: 'Model', cellsrenderer: this.renderer },
            { text: this.trans.instant('Date of purchase'), datafield: 'Date of Purchase', cellsrenderer: this.renderer },
            { text: this.trans.instant('Warrenty experiy date'), datafield: 'Warranty Expiry Date', cellsrenderer: this.renderer },
            { text: this.trans.instant('Sim card no'), datafield: 'SIM Card Number', cellsrenderer: this.renderer },
            { text: this.trans.instant('Status'), datafield: 'warrantyExpiry', cellsrenderer: this.renderer }
          ]
        }
        else if (app.entryPoint != "WFT" && app.entryPoint != "ATM")
          this.columns = [
            { text: this.trans.instant('Imei no'), datafield: 'IMEI Number', cellsrenderer: this.renderer },
            { text: this.trans.instant('Plate no'), datafield: 'Plate Number', cellsrenderer: this.renderer },
            { text: this.trans.instant('Manufacture name'), datafield: 'Manufacturer', cellsrenderer: this.renderer },
            { text: this.trans.instant('Model'), datafield: 'Model', cellsrenderer: this.renderer },
            { text: this.trans.instant('Date of purchase'), datafield: 'Date of Purchase', cellsrenderer: this.renderer },
            { text: this.trans.instant('Warrenty experiy date'), datafield: 'Warranty Expiry Date', cellsrenderer: this.renderer },
            { text: this.trans.instant('Sim card no'), datafield: 'SIM Card Number', cellsrenderer: this.renderer },
            { text: this.trans.instant('Status'), datafield: 'warrantyExpiry', cellsrenderer: this.renderer }
          ]
        else if (app.entryPoint == "ATM")
          this.columns = [
            { text: this.trans.instant('Imei no'), datafield: 'IMEI Number', cellsrenderer: this.renderer },
            { text: this.trans.instant('ATM ID'), datafield: 'Plate Number', cellsrenderer: this.renderer },
            { text: this.trans.instant('Manufacture name'), datafield: 'Manufacturer', cellsrenderer: this.renderer },
            { text: this.trans.instant('Model'), datafield: 'Model', cellsrenderer: this.renderer },
            { text: this.trans.instant('Date of purchase'), datafield: 'Date of Purchase', cellsrenderer: this.renderer },
            { text: this.trans.instant('Warrenty experiy date'), datafield: 'Warranty Expiry Date', cellsrenderer: this.renderer },
            { text: this.trans.instant('Sim card no'), datafield: 'SIM Card Number', cellsrenderer: this.renderer },
            { text: this.trans.instant('Status'), datafield: 'warrantyExpiry', cellsrenderer: this.renderer }
          ]
        else
          this.columns = [
            { text: this.trans.instant('Imei no'), datafield: 'IMEI Number', cellsrenderer: this.renderer },
            { text: this.trans.instant('Employee name'), datafield: 'Plate Number', cellsrenderer: this.renderer },
            { text: this.trans.instant('Manufacture name'), datafield: 'Manufacturer', cellsrenderer: this.renderer },
            { text: this.trans.instant('Model'), datafield: 'Model', cellsrenderer: this.renderer },
            { text: this.trans.instant('Date of purchase'), datafield: 'Date of Purchase', cellsrenderer: this.renderer },
            { text: this.trans.instant('Warrenty experiy date'), datafield: 'Warranty Expiry Date', cellsrenderer: this.renderer },
            { text: this.trans.instant('Sim card no'), datafield: 'SIM Card Number', cellsrenderer: this.renderer },
            { text: this.trans.instant('Status'), datafield: 'warrantyExpiry', cellsrenderer: this.renderer }
          ]
      })
  }

  async createPdf() {
    if (app.entryPoint == "WFT")
      this.head[1] = "Employee name"
    // For filtered rows
    const filteredRows = this.myGrid.getrows();
    const filteredData = filteredRows.map(row => {
      const obj = {};
      obj["IMEI Number"] = row["IMEI Number"];
      obj["Plate Number"] = row["Plate Number"];
      obj["Manufacturer"] = row.Manufacturer;
      obj["Model"] = row.Model;
      obj["Date Of Purchase"] = row["Date of Purchase"];
      obj["Warrenty experiy date"] = row["Warranty Expiry Date"];
      obj["SIM Card Number"] = row["SIM Card Number"];
      obj["Status"] = row.warrantyExpiry;
      return obj;
    });
    //For filtered rows
    this.commonService.createPdf(this.head, this.pdfdatas, filteredData, "Device Details", "", "Device Details", null)
  }


  exportToExcel() {
    // For filtered rows
    const filteredRows = this.myGrid.getrows();
    const filteredData = filteredRows.map(row => {
      const obj = {};
      obj["IMEI Number"] = row["IMEI Number"];
      obj["Plate Number"] = row["Plate Number"];
      obj["Manufacturer"] = row.Manufacturer;
      obj["Model"] = row.Model;
      obj["Date Of Purchase"] = row["Date of Purchase"];
      obj["Warrenty experiy date"] = row["Warranty Expiry Date"];
      obj["SIM Card Number"] = row["SIM Card Number"];
      obj["Status"] = row.warrantyExpiry;
      return obj;
    });
    //For filtered rows
    let reportData = {
      title: 'Device Details',
      data: this.pdfdatas,
      headers: this.head,
      data1: filteredData
    }
    this.ete.exportExcel(reportData);
  }

  ngOnInit() {
    if (localStorage.manageADM == "true" && localStorage.companyRole == "FleetManager") {
      this.submitbtn = true;
    }
    this.renderGridTable();
    this.localizationobj = {
      groupsheaderstring: this.trans.instant("Drag a column and drop it here to group by that column"),
    }
  }
}
