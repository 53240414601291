import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DateTimeService } from './date-time.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { serverUrl } from 'src/environments/environment';
import { CommonService } from './common.service';
@Injectable({
  providedIn: 'root',
})
export class ApiService {
  private APP_SECRET = '4aed41f193fc4288a228b9f951b68d07';
  private APP_KEY = '8FB345B8693CCD00B566E7D330B37353339A22A4105B6558';
  validUrl: String = "";


  iframeSrc!: SafeResourceUrl;

  constructor(private http: HttpClient,
    private dateTimeService: DateTimeService,
    private sanitizer: DomSanitizer,
    private cs: CommonService
  ) { }

  getCurrentTimestamp() {
    const date = new Date();
    const options = { timeZone: 'Asia/Kolkata', hour12: false };
    let a = date
      .toLocaleString('en-CA', { ...options, year: 'numeric', month: '2-digit', day: '2-digit' })
      .replace(/,/, '')
      + ' ' +
      date.toLocaleTimeString('en-GB', options);

    console.log("date time", a);

  }

  init() {
    this.http.get(serverUrl.web + "/support/get").subscribe(
      (res) => {
        console.log("res", res); this.makeApiCall(res);
      }
    )
  }

  public async makeApiCall(data): Promise<void> {
    const params: { [key: string]: string } = {
      method: 'jimi.oauth.token.get',
      timestamp: data.timestamp,
      app_key: this.APP_KEY,
      sign_method: 'md5',
      v: '1.0',
      format: 'json',
      user_id: 'GoldenElement01',
      user_pwd_md5: '49f01d04674b6205cea804665d7735f7',
      expires_in: '3600',
      sign: data.sign
    };
    const queryParams = new URLSearchParams(params).toString();
    const url = `https://eu.tracksolidpro.com/route/rest?${queryParams}`;
    this.http.post(url, queryParams).subscribe(
      (response) => {
        console.log('API Response:', response["result"]);
        response["result"]["sign"] = params['sign'];
        // this.first(response["result"])
        // localStorage.setItem("result", JSON.stringify(response["result"]))
        // this.first(response["result"])
        this.cs.tokObj = response["result"];


      },
      (error) => {
        console.error('API Error:', error);
      }
    );
  }

  first(data) {
    const params: { [key: string]: string } = {
      "method": "jimi.device.live.page.url",
      "timestamp": this.dateTimeService.getCurrentTimestampForLondon(),
      "app_key": "8FB345B8693CCD00B566E7D330B37353339A22A4105B6558",
      "sign": data.sign,
      "sign_method": "md5",
      "v": "0.9",
      "format": "json",
      "access_token": data.accessToken,
      "imei": "864993060102572",
      "type": "1",
      "V": "2",
      "token": data.refreshToken
    };
    // params['sign'] = "8939C00FFCE5B7105B1D36EA8BF496E7";
    const queryParams = new URLSearchParams(params).toString();
    const url = `https://eu.tracksolidpro.com/route/rest?${queryParams}`;
    let ResUrl = "";
    this.http.post(url, queryParams).subscribe(
      (response) => {
        console.log('API Response:', response["result"]);
        // window.open(response["result"].UrlCamera)
        ResUrl = response["result"].UrlCamera;
        this.validUrl = response["result"].UrlCamera;

        this.iframeSrc = this.sanitizer.bypassSecurityTrustResourceUrl(response["result"].UrlCamera)
      },
      (error) => {
        console.error('API Error:', error);
      }
    );
    // return ResUrl;
  }

  second(data) {
    // console.log(localStorage.result);
    // let loc = JSON.parse(localStorage.result)
    // console.log(loc);

    const params: { [key: string]: string } = {
      "method": "jimi.device.live.page.url",
      "timestamp": this.dateTimeService.getCurrentTimestampForLondon(),
      "app_key": "8FB345B8693CCD00B566E7D330B37353339A22A4105B6558",
      "sign": data.sign,
      "sign_method": "md5",
      "v": "0.9",
      "format": "json",
      "access_token": data.accessToken,
      "imei": "864993060102572",
      "type": "2",
      "V": "2",
      "token": data.refreshToken
    };
    // params['sign'] = "8939C00FFCE5B7105B1D36EA8BF496E7";
    const queryParams = new URLSearchParams(params).toString();
    const url = `https://eu.tracksolidpro.com/route/rest?${queryParams}`;
    let ResUrl = "";
    this.http.post(url, queryParams).subscribe(
      (response) => {
        console.log('API Response:', response["result"]);
        // window.open(response["result"].UrlCamera)
        ResUrl = response["result"].UrlCamera;
        this.validUrl = response["result"].UrlCamera;

        this.iframeSrc = this.sanitizer.bypassSecurityTrustResourceUrl(response["result"].UrlCamera)
      },
      (error) => {
        console.error('API Error:', error);
      }
    );
    // return ResUrl;
  }


}

