import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpEventType } from '@angular/common/http';
import { forkJoin } from 'rxjs';
import { catchError, tap, map } from 'rxjs/operators';
import { Observable, of, throwError } from 'rxjs';
import { AlertController, MenuController, Platform, ToastController } from '@ionic/angular';
import { app, serverUrl, storageVariable } from 'src/environments/environment';
import { CommonService } from './common.service';
import { FilesystemDirectory, Plugins } from '@capacitor/core';
import { Router } from '@angular/router';
import { WebsocketService } from './websocket.service';
const { Filesystem, Storage } = Plugins;
export const File_Key = 'files';

let httpOptionsWithString = {
    headers: new HttpHeaders({ 'Authorization': "Bearer " + storageVariable.authentication, "Access-Control-Allow-Origin": "localhost" }),
    // withCredentials: true
    Authorization: "Bearer " + storageVariable.authentication,
    responseType: 'text' as 'json'
};

let httpOptionsWithJson = {
    headers: new HttpHeaders({ 'Authorization': "Bearer " + storageVariable.authentication, 'Content-Type': 'application/json', "Access-Control-Allow-Origin": "localhost" }),
    // withCredentials: true ;charset=utf-8
    Authorization: "Bearer " + storageVariable.authentication
};
let httpOnlyAuthentication = {
    headers: new HttpHeaders({ 'Authorization': "Bearer " + storageVariable.authentication, "Access-Control-Allow-Origin": "localhost" })
};
let httpNormalString = {
    headers: new HttpHeaders({ 'Authorization': "Bearer " + storageVariable.authentication, 'Content-Type': 'application/x-www-form-urlencoded', "Access-Control-Allow-Origin": "localhost" }),
    responseType: 'text' as 'json',
    Authorization: "Bearer " + storageVariable.authentication
};

@Injectable({
    providedIn: 'root'
})
export class AjaxService {

    downloadprogress: number;
    myFiles = [];
    myPlatform: any;
    sessionClose: boolean = true;
    seperateUrlLogin: boolean = false;

    constructor(
        private http: HttpClient,
        public toastController: ToastController,
        public commonService: CommonService,
        private alertController: AlertController,
        private router: Router,
        private websocket: WebsocketService,
        private platform: Platform,
        private menuController: MenuController) { }


    fleetloginTime() {
        var json = {
            userId: localStorage.getItem('userName'),
            branchId: localStorage.getItem('corpId'),
            companyId: localStorage.getItem("corpId"),
            role: localStorage.getItem('companyRole'),
            macIp: ''
        }; 

        const url = serverUrl.web + '/login/logOut';
        this.ajaxPostWithString(url, json)
            .subscribe(res => {
                // console.log(res);

            });
    }
    async sessionCutAlert() {
        if (this.sessionClose) {
            this.fleetloginTime()
            this.sessionClose = false;
            this.myPlatform = this.platform.platforms()[0];
            if (this.myPlatform == 'tablet')
                this.myPlatform = 'desktop';
            if (this.myPlatform == "desktop") {
                if (sessionStorage.seprateLogin == 'true') {
                    this.seperateUrlLogin = true;
                } else {
                    this.seperateUrlLogin = false;
                }

                if (!this.seperateUrlLogin && window.location.hash !== '#/') {
                    const alert = await this.alertController.create({
                        header: 'Session Expired!',
                        backdropDismiss: false,
                        message: "The user session was timeout, Kindly logout the window",
                        buttons: [
                            {
                                text: 'logout',
                                handler: datas => {
                                    this.router.navigate(["tabs-login/members/login"], { skipLocationChange: true })
                                    localStorage.clear();
                                    this.websocket.disConnectSocket("");
                                    storageVariable.authentication = ""
                                    this.menuController.enable(false)
                                }
                            }]
                    });
                    await alert.present();
                } else if (this.seperateUrlLogin) {
                    const alert = await this.alertController.create({
                        header: 'Session Expired!',
                        backdropDismiss: false,
                        message: "The user session was timeout...",
                    });
                    await alert.present();
                }
            } else {
                this.callLoginServices();
            }
            
        }
    }

    handleError = async (error: HttpErrorResponse) => {
        let valid = true;
        let erHad = undefined;
        try {
            erHad = JSON.parse(error['error'])['error'];
        } catch (e) {
            valid = false;
        }
        try {
            if (!erHad)
                erHad = error['status'] == 401 ? error['status'] : "";
        } catch (e) {
            valid = false;
        }

        if (erHad == 401 ) {
            return this.sessionCutAlert();
        }
        if (this.commonService.isLoading == true) {
            this.commonService.dismissLoader();
        }
        console.log("Orginal Error" + JSON.stringify(error));
        if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', error.error.message);
        } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            console.error(
                `Backend returned code ${error.status}, ` +
                `body was: ${error.error}`);
        }
        // return an observable with a user-facing error message

        // this.toast('Something bad happened; please try again later.');
        //  const toast = await this.toastController.create({
        //     message: "Reaching server time out, please try after sometimes ",
        //     duration: 2000
        // });
        // toast.present();
        return null;
    }

    handleDeleteError = async (error: Response) => {
        if (error['status'] == 401) {
            console.log("Unauthorized  handleDeleteError = async");
            this.sessionCutAlert();
        }
        return error;
    }

    private returnhandleError = (error: HttpErrorResponse) => {
        if (error['status'] == 401) {
            console.log("Unauthorized  private returnhandleError");
            this.sessionCutAlert();
        }
        // if(error.status === 200) {
        return error.error.text;
        // }
    }

    private extractData = (res: Response) => {
        const body = res;
        return body || {};
    }
    private extractDataError = (res: Response) => {
        const body: any = JSON.stringify(res);
        return body;
    }
    private extractStringData = (res: Response) => {
        const body = res;
        return body || '';
    }
    // catchError(error => {
    //     if (error.error instanceof ErrorEvent) {
    //         console.log(error.error)
    //     } else {

    //     }
    //     return of([]);
    // })
    ajaxGet(url: string): Observable<any> {
        httpOptionsWithJson = this.headerFunctionCheck("httpOptionsWithJson")
        return this.http.get(url, httpOptionsWithJson)
            .pipe(
                map(this.extractStringData),
                catchError(this.handleError)
            );
    }
    ajaxGetwithouttoken(url: string): Observable<any> {
        return this.http
          .get(url)
          .pipe(map(this.extractStringData), catchError(this.toast));
      }
    ajaxGetJson(url: string): Observable<any> {
        httpOnlyAuthentication = this.headerFunctionCheck("httpOnlyAuthentication")
        return this.http.get(url, httpOnlyAuthentication)
            .pipe(
                map(this.extractData),
                catchError(this.handleError)
            );
    }
    ajaxGetPerference(url: string): Observable<any> {
        httpOnlyAuthentication = this.headerFunctionCheck("httpOnlyAuthentication")
        return this.http.get(url, httpOnlyAuthentication)
            .pipe(
                map(this.extractStringData),
                catchError(this.handleError)
            );
    }
    ajaxGetObject(url: string): Observable<any> {
        httpNormalString = this.headerFunctionCheck("httpNormalString")
        return this.http.get(url, httpNormalString)
            .pipe(
                map(this.extractData),
                catchError(this.handleError)
            );
    }
    ajaxGetWithString(url: string): Observable<any> {
        httpOptionsWithString = this.headerFunctionCheck("httpOptionsWithString")
        return this.http.get(url, httpOptionsWithString)
            .pipe(
                map(this.extractStringData),
                catchError(this.handleError)
            )
    }

    ajaxGetWithBody(url: string): Observable<any> {
        // let newurl = "/Web/api/company/user/checkname/9600696008";
        httpOptionsWithString = this.headerFunctionCheck("httpOptionsWithString")
        return this.http.get(url, httpOptionsWithString)
            .pipe(
                map(this.extractData),
                catchError(this.handleError)
            );
    }
    ajaxPutAsText(url: string, data: any): Observable<any> {
        httpOptionsWithString = this.headerFunctionCheck("httpOptionsWithString")
        return this.http.put(url, data, httpOptionsWithString)
            .pipe(
                map(this.extractStringData),
                catchError(this.handleError)
            );
    }
    ajaxGetWithErr(url: string) {
        httpOnlyAuthentication = this.headerFunctionCheck("httpOnlyAuthentication")
        return this.http.get(url, httpOnlyAuthentication)
            .pipe(
                map(this.extractStringData),
                catchError(this.handleDeleteError)
            );
    }
    ajaxGetWithBodyWithoutString(url: string): Observable<any> {
        httpOptionsWithJson = this.headerFunctionCheck("httpOptionsWithJson")
        return this.http.get(url, httpOptionsWithJson)
            .pipe(
                map(this.extractData),
                catchError(this.handleError)
            );
    }

    ajaxPostWithBody(url: string, data: any): Observable<any> {
        httpOptionsWithJson = this.headerFunctionCheck("httpOptionsWithJson")
        return this.http.post(url, data, httpOptionsWithJson)
            .pipe(
                map(this.extractStringData),
                catchError(this.handleError)
            );
    }

    ajaxPostWithString(url: string, data: any): Observable<any> {
        httpOptionsWithString = this.headerFunctionCheck("httpOptionsWithString")
        return this.http.post(url, data, httpOptionsWithString)
            .pipe(
                map(this.extractData),
                catchError(this.handleError)
            );
    }

    ajaxPutMethod(url: string, data: any): Observable<any> {
        httpOptionsWithString = this.headerFunctionCheck("httpOptionsWithString")
        return this.http.put(url, data, httpOptionsWithString)
            .pipe(
                map(this.extractStringData),
                catchError(this.handleError)
            );
    }

    ajaxPutMethod2(url: string, data: FormData): Observable<any> {
        httpOnlyAuthentication = this.headerFunctionCheck("httpOnlyAuthentication")
        return this.http.put(url, data, httpOnlyAuthentication)
            .pipe(
                map(this.extractStringData),
                catchError(this.handleError)
            );
    }

    ajaxPostMethod(url: string, data: any): Observable<any> {
        httpOnlyAuthentication = this.headerFunctionCheck("httpOnlyAuthentication")
        return this.http.post(url, data, httpOnlyAuthentication)
            .pipe(
                map(this.extractData),
                catchError(this.handleDeleteError)
            )
    }
    ajaxPostMethodWithoutData(url: string): Observable<any> {
        httpOptionsWithString = this.headerFunctionCheck("httpOptionsWithString")
        return this.http.post(url, httpOptionsWithString, httpOptionsWithString)
            .pipe(
                map(this.extractStringData),
                catchError(this.returnhandleError)
            );
    }

    ajaxPostWithErrorBody(url: string, data: any): Observable<any> {
        httpOptionsWithJson = this.headerFunctionCheck("httpOptionsWithJson")
        return this.http.post(url, data, httpOptionsWithJson)
            .pipe(
                map(this.extractDataError),
                catchError(this.returnhandleError)
            );
    }

    requestDataFromMultipleSources(url: string, data: any): Observable<any[]> {
        httpOptionsWithString = this.headerFunctionCheck("httpOptionsWithString")
        const responses = [];
        data.forEach(element => {
            const detailsforinsert = {};
            detailsforinsert['type'] = element.type;
            detailsforinsert['id'] = element.id;
            responses.push(this.http.put(url, JSON.stringify(detailsforinsert), httpOptionsWithString));
        });
        // Observable.forkJoin (RxJS 5) changes to just forkJoin() in RxJS 6
        return forkJoin(responses);
    }
    async toast() {
        // const toast = await this.toastController.create({
        //     message: "Something went wrong",
        //     duration: 2000
        // });
        // toast.present();
        console.log("Something went wrong");
    }

    deleteMultipleData(url: string, data: any): Observable<any[]> {
        const responses = [];
        data.forEach(element => {
            let detailsforinsert = {};
            detailsforinsert['type'] = element.type;
            detailsforinsert['id'] = element.id;
            const options = {
                headers: new HttpHeaders({
                    'responseType': 'JSON',
                    'Authorization': "Bearer " + storageVariable.authentication,

                    "Access-Control-Allow-Origin": "localhost"
                }),
                body: detailsforinsert
            }
            responses.push(this.http.delete(url, options));
        });
        // Observable.forkJoin (RxJS 5) changes to just forkJoin() in RxJS 6
        return forkJoin(responses);
    }
    ajaxDeleteWithBody(url: string, data: object): Observable<any> {
        const options = {
            headers: new HttpHeaders({
                'responseType': 'JSON',

                'Authorization': "Bearer " + storageVariable.authentication,
            }),
            body: data
        }
        return this.http.delete(url, options)
            .pipe(
                map(this.extractStringData),
                catchError(this.handleDeleteError)
            );
    }


    ajaxReportServices(reportName: string, body: object, outputRoute: string, callback: any, ajaxCall: string) {
        if (ajaxCall != "get") {
            const url = serverUrl.web + '' + reportName
            this.ajaxPostMethod(url, body)
                .subscribe(
                    res => {
                        console.log(res);
                        callback(res, outputRoute);
                    }
                );
        } else if (outputRoute != "recurrence") {
            const url = serverUrl.web + '' + reportName + '' + (typeof body === "object" ? JSON.stringify(body) : body)
            this.ajaxGet(url)
                .subscribe(res => {
                    console.log(res);
                    callback(res, outputRoute)
                })
        } else {
            const url = serverUrl.web + '' + reportName + '' + JSON.stringify(body);
            this.ajaxGetWithString(url)
                .subscribe(res => {
                    console.log(res);
                    callback(res, outputRoute)
                })
        }
    }

    ajaxPutMethodJson(url: string, data: any): Observable<any> {
        httpOptionsWithJson = this.headerFunctionCheck("httpOptionsWithJson")
        return this.http.put(url, data, httpOptionsWithJson)
            .pipe(
                map(this.extractStringData),
                catchError(this.handleError)
            );
    }


    ajaxDeleteWithString(url: string): Observable<any> {
        const options = {
            headers: new HttpHeaders({
                'responseType': 'JSON',

                'Authorization': "Bearer " + storageVariable.authentication,

                "Access-Control-Allow-Origin": "localhost"
            }),

        }
        return this.http.delete(url, options)
            .pipe(
                map(this.extractStringData),
                catchError(this.handleDeleteError)
            );
    }

    ajaxGetFile(url) {
        return this.http.get(url, {
            responseType: 'blob',
            reportProgress: true,
            observe: 'events'
        })
    }

    headerFunctionCheck(headertest) {
        let check = {
            httpOptionsWithString: {
                headers: new HttpHeaders({ 'Authorization': "Bearer " + storageVariable.authentication, "Access-Control-Allow-Origin": "localhost" }),
                // withCredentials: true
                Authorization: "Bearer " + storageVariable.authentication,
                responseType: 'text' as 'json',

            },

            httpOptionsWithJson: {
                headers: new HttpHeaders({ 'Authorization': "Bearer " + storageVariable.authentication, 'Content-Type': 'application/json;charset=utf-8', "Access-Control-Allow-Origin": "localhost" }),
                // withCredentials: true
                Authorization: "Bearer " + storageVariable.authentication,

            },

            httpOnlyAuthentication: {
                headers: new HttpHeaders({ 'Authorization': "Bearer " + storageVariable.authentication })
            },
            httpNormalString: {
                headers: new HttpHeaders({ 'Authorization': "Bearer " + storageVariable.authentication, 'Content-Type': 'application/x-www-form-urlencoded', "Access-Control-Allow-Origin": "localhost" }),
                responseType: 'text' as 'json',
                Authorization: "Bearer " + storageVariable.authentication
            }
        }
        return check[headertest]
    }

    callLoginServices() {

        var platform = this.myPlatform
        if (/(android|iPhone|iPad|iPod)/i.test(navigator.userAgent)) {
            platform = "mobile"
        }
        let body = {
            "username": localStorage.userName,
            "password": localStorage.password,
            "corpid": localStorage.corpId,
            "loginMode": "mobile",
            "entryPoint": app.entryPoint,
            "appsetting": "vts_mobile",
            "platform": platform
        }
        let url = serverUrl.web + "/login/company"
        this.ajaxPostMethod(url, body)
            .subscribe(res => {
                console.log(res);
                if (res != undefined) {
                    if (res.length > 1) {
                        storageVariable.authentication = res[1]["token"]
                    }
                }
            });

    }
    groupService() {
        const url = serverUrl.web + "/site/getVinGroup";
        let body = {
            companyId: localStorage.corpId,
            branchId: localStorage.corpId,
            userId: localStorage.userName
        }
        if (body.companyId && body.branchId && body.userId) {
            this.ajaxPostMethod(url, body)
                .subscribe(res => {
                    storageVariable.groupAndVin = res;
                })
        }
    }
}
