import { Component, OnInit, Input } from '@angular/core';
import { AjaxService } from '../../services/ajax.service';
import { languageInitializer, serverUrl } from '../../../environments/environment';
import { Platform } from '@ionic/angular';
import { app } from '../../../environments/environment';
import { CommonService } from 'src/app/services/common.service';
@Component({
  selector: 'app-vts-info',
  templateUrl: './vts-info.component.html',
  styleUrls: ['./vts-info.component.scss'],
})
export class VtsInfoComponent implements OnInit {
  map;
  liveData;
  selectedVin;
  @Input() gridView;
  moreData = true;
  callsign: String = "";
  vin: any;
  infoType:string;
  entryPoint: any;
  directory = languageInitializer.directory;
  TUV: Boolean = false;
  TuvDate: any = "---";
  AVP: boolean = false;
  AvpDate: any = "---";
  constructor(
    public ajaxService: AjaxService,
    private platform: Platform,
    private commonService: CommonService
  ) {
  }
  ionViewWillEnter() {

  }
  toggleData() {
    this.moreData = !this.moreData;
  }

  changeToArabic(letter) {
    return this.commonService.numberToArabic(letter.toString());
  }

  splitNameArabic(name) {
    return this.commonService.splitNameArabic(name, this.directory)
  }

  ngOnInit() {
    this.entryPoint = app.entryPoint;
    this.callsign = localStorage.callsign;
    this.commonService.updateLanguageDir
      .subscribe(res => {
        this.directory = res == "" ? this.directory : res
      })
    // if (this.gridView) {
    //   this.selectedVin = this.gridView;
    // } else {
    //   this.selectedVin = JSON.parse(localStorage.selectedVin).vin;
    // }
    // let url: string = serverUrl.web + "/site/vehicleInfo?vin=" + this.selectedVin;
    // this.ajaxService.ajaxGet(url)
    //   .subscribe(res => {
    //     this.liveData = res;
    //   })
  }
  ngOnChanges(changes): void {
    this.infoType = JSON.parse(localStorage.selectedVin).icon
    if (this.gridView) {
      this.selectedVin = this.gridView;
    } else {
      this.selectedVin = JSON.parse(localStorage.selectedVin).vin;
    }
    let url: string = serverUrl.web + "/site/vehicleInfo?vin=" + this.selectedVin;
    this.ajaxService.ajaxGet(url)
      .subscribe(res => {
        this.liveData = res;
        let arr = []
        Object.keys(this.liveData).map(el => {
          if (el == "TUV-ExpriyDate") {
            arr.push("TUV-ExpriyDate");
          } if (el == "AVP-ExpriyDate") {
            arr.push("AVP-ExpriyDate");
          }
        })
        if (arr.includes("TUV-ExpriyDate")) {
          this.TUV = true;
          this.TuvDate = this.liveData['TUV-ExpriyDate'].split(' ')[0];
        }
        if (arr.includes("AVP-ExpriyDate")) {
          this.AVP = true;
          this.AvpDate = this.liveData['AVP-ExpriyDate'].split(' ')[0];
        }
        if (arr.length == 0) {
          this.AVP = false; this.TUV = false;
        }
        if (this.liveData['AVP-ExpriyDate'] === "---") {
          this.AVP = false;
        }
        if (this.liveData['TUV-ExpriyDate'] === "---") {
          this.TUV = false;
        }

      })

  }

}
