import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { jqxGridComponent } from 'jqwidgets-ng/jqxgrid';
import { jqxTreeComponent } from 'jqwidgets-ng/jqxtree';
import { jqxComboBoxComponent } from 'jqwidgets-ng/jqxcombobox'
import { jqxGaugeComponent } from 'jqwidgets-ng/jqxgauge'
import { jqxDateTimeInputModule } from "jqwidgets-ng/jqxdatetimeinput";
// import { jqxGridComponent } from 'jqwidgets-scripts/jqwidgets-ts/angular_jqxgrid';
import { jqxButtonModule } from 'jqwidgets-ng/jqxbuttons';
import { TreeViewModule } from '@syncfusion/ej2-angular-navigations';
import { DashCamSearchPipe } from '../services/dashCamSearch.pipe';
import { DashCamFilterPipe } from '../services/dashcamfilter.pipe';


@NgModule({
  declarations: [jqxGridComponent, jqxTreeComponent, jqxComboBoxComponent, jqxGaugeComponent, DashCamSearchPipe, DashCamFilterPipe],
  imports: [
    CommonModule
  ],
  exports: [
    jqxGridComponent,
    jqxTreeComponent,
    jqxComboBoxComponent,
    jqxButtonModule,
    jqxGaugeComponent,
    TreeViewModule,
    jqxDateTimeInputModule,
    DashCamSearchPipe, DashCamFilterPipe
  ]
})
export class SharedModModule { }
